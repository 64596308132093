import React from "react";
import errorImage from "../../../../assets/error.png";

function Error(props: any) {
  const { error } = props;
  return (
    <div className="bg-[#0000006e] flex p-[20px] mt-[20px] items-center">
      <img src={errorImage} alt="" />
      <p className="pl-[10px] text-[#575757]">
        {error === undefined
          ? "Please fill the input fields correctly"
          : JSON.stringify(error).replaceAll('"', "")}
      </p>
    </div>
  );
}

export default Error;
