import './NotWhitelisted.css'

function NotWhitelisted() {
  return (
      <div className='notwhitelisted__container'>
          <div className='text-[32px] sm:text-[30px] w-[auto]'>Uh oh, this is taking longer than expected. We are unable to generate your API endpoint immediately.</div>
          <div className='notwhitelisted__subcont'>
              <div className='text-[24px] sm:text-[20px]'>
                Please wait for a message from us about the next steps on discord or email.
              </div>
          </div>
      </div>
  )
}

export default NotWhitelisted