import React from 'react'

function Todo() {
  return (
    <>
    <div className="pl-7">
        <p className="text-[22px] pt-8">Todo</p>
        <p className="pt-[35px]">Wallet Address</p>
        <div className="pt-[10px] flex pr-[34px]">
        <input type="text" className="bg-bg-[#323131] border border-[#B0B0B0] w-[505px] text-white text-sm rounded-sm p-2.5 dark:bg-[#323131] dark:text-white"/>
        <button className="ml-[15px] bg-gradient-to-r from-[#F55151] to-[#FFB800] text-white text-base w-[161px] h-[46px] py-2 px-4 rounded">
            Check
        </button>
        </div>
    </div>
    <hr className="ml-[6px] mr-[6px] mt-[42px] bg-white"/>
</>
  )
}

export default Todo