import React, { useState } from "react";
import InnerDashboard from "./InnerDashboard/InnerDashboard";
import Discord from "../assets/discord.png";
import Twitter from "../assets/twitter.png";
import HelloEmoji from "../assets/helloEmoji.png";
import { NavLink } from "react-router-dom";
import { DISCORD_LINK, TWITTER_LINK } from "../../../constants";

function OuterDashboard(props: any) {
  const [currentAPI, setCurrentAPI] = useState<any>("TotalBalance");

  const handleClick = (e: any) => {
    const { name } = e.currentTarget;
    setCurrentAPI(name);
  };
  return (
    <>
      <div className="max-w-[1260px] mt-[63px] pt-[32px] w-full h-[100%]">
        <p style={{ fontSize: "37px" }} className='colored-grad pt-[12px]'>Playground</p>
        <div className="flex justify-between mb-[46px] mt-[10px] w-full">
          <div className="text-left flex justify-center items-center">
            <div className="pr-[21px]">
              <img src={HelloEmoji} alt="" />
            </div>
            <div>
              <p className="text-[16px] text-[#efefefcc]">Welcome Fam,</p>
              <p className="text-[16px] text-[#efefefcc]">
                Test our APIs and start building your project for FREE!
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <NavLink to="/api">
              <button className="bg-transparent hover:text-white text-[#efefefcc] text-sm py-2 px-4 border border-[#333232] rounded shadow">
                Start Building for FREE
              </button>
            </NavLink>
            <a href={DISCORD_LINK} rel="noreferrer" target="_blank">
              <img src={Discord} className="mx-[10px] h-[36px]" alt="" />
            </a>
            <a href={TWITTER_LINK} rel="noreferrer" target="_blank">
              <img src={Twitter} className="h-[36px]" alt="" />
            </a>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <div className="mr-[23px]">
            <table className="table ">
              <tr>
                <td className="">
                  <button
                    onClick={handleClick}
                    name="TotalBalance"
                    className={`pl-5 ${currentAPI === "TotalBalance"
                      ? " bg-[#0000006e] border-b-[2px] border-b-[#F55151]  text-[#efefefcc]"
                      : "text-[#575757]"
                      } hover:bg-[#fb881e1a] hover:text-white text-[16px] h-[68px] w-[293px] text-left`}
                  >
                    Check Wallet Balance
                  </button>
                </td>
              </tr>
              <tr>
                <td className="">
                  <button
                    onClick={handleClick}
                    name="NftCollection"
                    className={`pl-5 ${currentAPI === "NftCollection"
                      ? "bg-[#0000006e] border-b-[2px] border-b-[#F55151]  text-[#efefefcc]"
                      : "text-[#575757]"
                      } hover:bg-[#fb881e1a] hover:text-white text-[16px] h-[68px] w-[293px] text-left`}
                  >
                    Fetch NFT Collection Info
                  </button>
                </td>
              </tr>
              <tr>
                <td className="">
                  <button
                    onClick={handleClick}
                    name="NftMetadata"
                    className={`pl-5 ${currentAPI === "NftMetadata"
                      ? " bg-[#0000006e] border-b-[2px] border-b-[#F55151]  text-[#efefefcc]"
                      : "text-[#575757]"
                      } hover:bg-[#fb881e1a] hover:text-white text-[16px] h-[68px] w-[293px] text-left`}
                  >
                    Fetch NFT Metadata
                  </button>
                </td>
              </tr>
              <tr>
                <td className="">
                  <button
                    onClick={handleClick}
                    name="DomainResolver"
                    className={`pl-5 ${currentAPI === "DomainResolver"
                      ? "bg-[#0000006e] border-b-[2px] border-b-[#F55151]  text-[#efefefcc]"
                      : "text-[#575757]"
                      } hover:bg-[#fb881e1a] hover:text-white text-[16px] h-[68px] w-[293px] text-left`}
                  >
                    Domain Lookup
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div className="rounded w-full">
            <div className="w-full">
              <InnerDashboard currentAPI={currentAPI} {...props} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OuterDashboard;
