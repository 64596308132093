const JOTFORM_LINK = "https://form.jotform.com/222131373921447";
const DOCUMENTATION_LINK = "https://concise-labs.gitbook.io";
const SUPPORT_LINK = "https://conciselabs-support.freshdesk.com/";
const DISCORD_LINK = "http://discord.gg/5qPBtderXs";
const TWITTER_LINK = "https://twitter.com/ConciseLabs";
const BLOG_LINK = "https://www.wordcelclub.com/conciselabs.sol";
const CASESTUDIES_LINK = "https://medium.com/@ConciseLabs";

export {
  JOTFORM_LINK,
  DOCUMENTATION_LINK,
  SUPPORT_LINK,
  DISCORD_LINK,
  TWITTER_LINK,
  BLOG_LINK,
  CASESTUDIES_LINK,
};
