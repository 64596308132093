import { useState } from "react";
import ProjectDetails from "../OnboardingForm/MultiStepForm/ProjectDetails";
import ContractDetails from "../OnboardingForm/MultiStepForm/ContractDetails";
import * as yup from "yup";
import Back from "../Playground/assets/back.png"

function StarknetOnboardingForm(props: any) {
  const { chain, setChooseChain, setChain } = props;
  const [page, setPage] = useState(0);
  const formTitles = ["Project Details", "Contract Details"];
  const [isFile, setIsFile] = useState(false);
  const [error, setError] = useState<any>({});
  const [fileName, setFileName] = useState("");

  // form state
  const initialValue = {
    programName: "",
    website: "",
    email: "",
    twitter: "",
    discord: "",
    programId: "",
    idl: "",
    env: "starknet",
  };
  const [formValues, setFormValue] = useState(initialValue);

  // changing form page
  const pageDisplay = () => {
    if (page === 0) {
      return (
        <ProjectDetails
          formValues={formValues}
          setFormValue={setFormValue}
          error={error}
          validateInput={validateInput}
        />
      );
    }
    return (
      <ContractDetails
        formValues={formValues}
        setFormValue={setFormValue}
        setIsFile={setIsFile}
        isFile={isFile}
        error={error}
        validateInput={validateInput}
        setError={setError}
        fileName={fileName}
        setFileName={setFileName}
        chain={chain}
      />
    );
  };

  // form validations
  const validateInput = async (inputType: string, value: string) => {
    let current_error = "";
    switch (inputType) {
      case "programName":
        current_error = "";
        await yup
          .object()
          .shape({ inputType: yup.string().min(3).max(30).required() })
          .validate({ inputType: value })
          .catch((err) => {
            current_error = err.errors[0];
          });
        setError({
          ...error,
          [inputType]: current_error
            ? "Contract Name should be more than 3 characters"
            : "",
        });
        break;

      case "website":
        current_error = "";
        await yup
          .object()
          .shape({
            inputType: yup.string(),
          })
          .validate({ inputType: value })
          .catch((err) => {
            current_error = err.errors[0];
          });
        setError({
          ...error,
          [inputType]: current_error ? "Please enter a valid website" : "",
        });
        break;

      case "email":
        current_error = "";
        await yup
          .object()
          .shape({
            inputType: yup
              .string()
              .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
              .email()
              .required(),
          })
          .validate({ inputType: value })
          .catch((err) => {
            current_error = err.errors[0];
          });
        setError({
          ...error,
          [inputType]: current_error ? "Please enter a valid email" : "",
        });
        break;

      case "twitter":
        current_error = "";
        await yup
          .object()
          .shape({
            inputType: yup
              .string()
              .matches(/^@?(\w){1,15}$/g)
              .required(),
          })
          .validate({ inputType: value })
          .catch((err) => {
            current_error = err.errors[0];
          });
        setError({
          ...error,
          [inputType]: current_error ? "Invalid Twitter ID" : "",
        });
        break;

      case "discord":
        current_error = "";
        await yup
          .object()
          .shape({ inputType: yup.string().min(4).required() })
          .validate({ inputType: value })
          .catch((err) => {
            current_error = err.errors[0];
          });
        setError({
          ...error,
          [inputType]: current_error ? "Invalid Discord ID" : "",
        });
        break;

      case "programId":
        current_error = "";
        await yup
          .object()
          .shape({ inputType: yup.string().min(32).required() })
          .validate({ inputType: value })
          .catch((err) => {
            current_error = err.errors[0];
          });
        setError({
          ...error,
          [inputType]: current_error
            ? "Contract ID should be at least 32 characters"
            : "",
        });
        break;

      default:
        setError({ ...error });
    }
  };

  const isFormValid = () => {
    if (
      formValues.programName === "" ||
      formValues.website === "" ||
      formValues.email === "" ||
      formValues.twitter === "" ||
      formValues.discord === ""
    ) {
      return false;
    }

    const errors = Object.values(error);
    const noErrors = errors.every((err) => {
      return err === ""; // check every field for empty error
    });

    return noErrors;
  };

  const handleBack = () => {
    if (isFormValid()) {
      setPage((currPage) => currPage - 1);
    }
  };

  // handle Next
  const handleNext = async () => {
    if (isFormValid()) {
      setPage((currPage) => currPage + 1);
    }
  };

  // handle submit
  const initOnboard = async () => {
    if (isFormValid() && isFile && formValues.programId.length >= 32) {
      props.setFormData({
        ...formValues,
        programName: formValues.programName.toLowerCase(),
      });
    } else if (!isFile) {
      setError({ ...error, idl: "Please Upload ABI" });
    } else if (formValues.programId.length < 32) {
      setError({
        ...error,
        programId: "Contract ID should be at least 32 characters",
      });
    }
  };

  return (
    <div className="px-[52px] pt-[35px] sm md lg xl 2xl mt-[156px] rounded-md overflow-hidden shadow-lg bg-[#00000066] min-w-[600px] h-[643px]">
      <span onClick={() => { setChooseChain(""); setChain("") }} className="absolute cursor-pointer" style={{ marginLeft: "-283px", marginTop: "-20px" }}><img src={Back} alt="go back" /></span>
      {/* <div className="px-6 py-4"> */}
      <div className="text-[24px] text-left">Submit Project details</div>

      {/* Progress Bar */}
      <div className="flex items-center mt-[28px] mb-[28px]">
        <div className="flex">
          <svg height="14" width="13">
            <circle
              cx="6"
              cy="7.5"
              r="6"
              fill={page === 1 ? "#3DD875" : "#FF7A00"}
            />
          </svg>
          <p
            className={`text-[11.65px] pl-[3.3px] flex ${page === 0 ? "" : "text-[#CAC8C8]"
              } justify-center items-center`}
          >
            Project Details
          </p>
        </div>
        <div>
          <svg height="40" width="54" className="opacity-50">
            <line
              x1="5"
              y1="19"
              x2="100"
              y2="19"
              stroke="white"
              strokeWidth={1}
            />
          </svg>
        </div>
        <div className="flex pl-[3.3px] pr-[3.3px]">
          <svg height="14" width="13">
            <circle
              cx="6.5"
              cy="7"
              r="6"
              fill={page === 1 ? "#FF7A00" : "none"}
              stroke={page === 1 ? "none" : "white"}
              strokeWidth={1}
            />
          </svg>
          <p
            className={`text-[11.65px] pl-[3.3px] flex ${page === 1 ? "" : "text-[#CAC8C8]"
              } justify-center items-center`}
          >
            Upload ABI
          </p>
        </div>
      </div>
      <form className="rounded w-[292px] min-h-[417px]" autoComplete="off">
        {pageDisplay()}
      </form>
      <div className="object-left-bottom sm md lg xl 2xl flex items-center">
        <button
          className="bg-[#1A1D23] border border-white mr-2 text-xs text-gray-300 font-bold py-2 px-10 rounded-[81px] focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleBack}
          hidden={page < 1}
          disabled={!isFormValid()}
        >
          Back
        </button>
        <button
          className="bg-[#FF7A00] text-xs text-white font-bold py-2 px-10 rounded-[81px] focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleNext}
          hidden={page === formTitles.length - 1}
          disabled={!isFormValid()}
        >
          Next
        </button>
        <button
          className="bg-[#FF7A00] text-xs text-white font-bold py-2 px-10 rounded-[81px] focus:outline-none focus:shadow-outline"
          type="button"
          hidden={page === formTitles.length - 1 ? false : true}
          onClick={initOnboard}
          disabled={!isFormValid()}
        >
          Submit
        </button>
      </div>
      {/* </div> */}
    </div>
  );
}

export default StarknetOnboardingForm;
