import TotalBalance from "./AllDashboards/TotalBalance";
import NftMetadata from "./AllDashboards/NftMetadata";
import GetNotification from "./AllDashboards/GetNotification";
import DomainResolver from "./AllDashboards/DomainResolver";
import Todo from "./AllDashboards/Todo";
import NftCollection from "./AllDashboards/NftCollection";
import confetti from "canvas-confetti";

function InnerDashboard(props: any) {
  const renderConfetti = () => {
    const item = localStorage.getItem("isFirstApiCall");

    if (item) {
      return;
    }
    confetti({
      particleCount: 400,
      spread: 70,
      origin: { y: 0.6 },
    });

    localStorage.setItem("isFirstApiCall", "true");
  };

  const { currentAPI } = props;
  const resolveDashboard = () => {
    switch (currentAPI) {
      case "TotalBalance":
        return <TotalBalance {...props} renderConfetti={renderConfetti} />;
      case "NftMetadata":
        return <NftMetadata {...props} renderConfetti={renderConfetti} />;
      case "NftCollection":
        return <NftCollection {...props} renderConfetti={renderConfetti} />;
      case "GetNotification":
        return <GetNotification {...props} renderConfetti={renderConfetti} />;
      case "DomainResolver":
        return <DomainResolver {...props} renderConfetti={renderConfetti} />;
      case "Todo":
        return <Todo />;
    }
  };
  return <>{resolveDashboard()}</>;
}

export default InnerDashboard;
