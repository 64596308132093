import React, { useState } from "react";
import nameServiceBG from "../../../assets/name-service.png";
import Error from "./Error/Error";
import {
  getAllDomainOfWallet,
  getWalletOfADomain,
} from "../../../../../apiHelper";
import CircularProgress from "@mui/material/CircularProgress";
import copyImg from "../../../assets/copy.png";
import copied from "../../../assets/copied.png";
import SubmitButtonSvg from "../../../assets/submitButton.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import EthLogo from "../../../assets/EthLogo.png";
import SolanaLogo from "../../../assets/SolanaLogo.png";

function DomainResolver(props: any) {
  const { currentUser, renderConfetti } = props;
  const [isCopied, setisCopied] = useState(false);
  const [copyItemIndex, setCopyItemIndex] = useState();
  const [isLoading, setLoading] = useState(false);
  const [chain, setChain] = useState("Solana");
  const [mode, setMode] = useState("domain to owner");
  const [userInput, setUserInput] = useState<any>("lucifer");
  const [result, setResult] = useState<any>({});
  const [errorState, setErrorState] = useState<any>(false);
  const [error, setError] = useState<any>(undefined);
  const [placeholder, setPlaceholder] = useState("austin.sol")
  const [visibility, setVisibility] = useState<boolean>(true);
  const [displayChain,setdisplayChain] = useState("Solana");
  const handleUserInputChange = (e: any) => {
    const { value } = e.currentTarget;
    setUserInput(value);
  };

  const handleChainChange = (e: any) => {
    const { value } = e.currentTarget;
    setChain(value);
    setUserInput("")
    if (value === "Solana") {
      setdisplayChain("Solana");
      if (mode === "domain to owner") {
        setPlaceholder("austin.sol")
      } else {
        setPlaceholder("wallet address")
      }
    } else {
      setdisplayChain("Ethereum");
      setPlaceholder("alice.eth")
      setMode("domain to owner");
      setUserInput("alice.eth");
      // if (mode === "domain to owner") {
      //   setPlaceholder("alice.eth")
      // } 
      // else {
      //   setPlaceholder("wallet address")
      // }
    }
  };

  const fetchAllDomain = async (network: string) => {
    setLoading(true);
    const res = await getAllDomainOfWallet(userInput, currentUser.apiToken, network);
    if (res.error !== undefined || res.status === false) {
      setError(res.error);
      handleError();
    }
    setResult(res);
    renderConfetti();
    setLoading(false);
  };
  const fetchAddress = async (network: string) => {
    setLoading(true);
    const res = await getWalletOfADomain(userInput, currentUser.apiToken, network);
    if (res.error !== undefined || res.status === false) {
      setError(res.error);
      handleError();
    }
    setResult(res);
    renderConfetti();
    setLoading(false);
  };

  const handleDomainToOwner = () => {
    setResult({});
    if (chain === "Solana") {
      setMode("domain to owner");
      setUserInput("lucifer");
    } else {
      setMode("domain to owner");
      setUserInput("alice.eth");
    }

  };
  const handleWalletToDomains = () => {
    setResult({});
    if (chain === "Solana") {
      setMode("wallet to domains");
      setUserInput("CdXU3s7AFPSoqAdSq4fTXsDeTvgeiVv61kgSYDLDL2DF");
    } else {
      setMode("domain to owner");
      setUserInput("alice.eth");
    }

  };

  async function copyTextToClipboard(value: any, index: any = undefined) {
    if (index !== undefined) {
      setCopyItemIndex(index);

      setTimeout(() => {
        setCopyItemIndex(undefined);
      }, 5000);
    }
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(value);
    } else {
      return document.execCommand("copy", true, value);
    }
  }

  const handleError = () => {
    setErrorState(true);
    setTimeout(() => {
      setErrorState(false);
      setError("");
    }, 5000);
  };

  const handleCopy = () => {
    setisCopied(true);
    setTimeout(() => {
      setisCopied(false);
    }, 5000);
  };

  const handleSubmit = async () => {
    try {
      if (chain === "Solana") {
        if (mode === "domain to owner") {
          await fetchAddress("solana-mainnet");
        } else if (mode === "wallet to domains") {
          await fetchAllDomain("solana-mainnet");
        }
      } else if (chain === "Ethereum") {
        if (mode === "domain to owner") {
          await fetchAddress("eth-mainnet");
        } else if (mode === "wallet to domains") {
          await fetchAllDomain("eth-mainnet");
        }
      }
      else {
        handleError();
        setLoading(false);
      }
    } catch (error: any) {
      handleError();
      console.log(error);
      setLoading(false);
    }
  };

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  return (
    <div className="pb-[20px]">
      <div className="bg-[#0000006e] w-full rounded">
        <div className="text-[20px] text-[#C8C8C8] font-[500] px-[20px] text-left pt-[20px]">
          Domain Lookup
        </div>
        <div className="flex pt-[20px] pb-[16px]">
          <div className="flex items-center pl-[20px]">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={mode}
              onChange={handleDomainToOwner}
            >
              <FormControlLabel
                value={mode}
                control={<Radio checked={mode === "domain to owner"} />}
                label="Get Owner by Domain"
              />
            </RadioGroup>
          </div>
          {chain === "Ethereum" ? "" :
            <div className="flex items-center">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={mode}
                onChange={handleWalletToDomains}
              >
                <FormControlLabel
                  value={mode}
                  control={<Radio checked={mode === "wallet to domains"} />}
                  label="List Owned Domains"
                />
              </RadioGroup>
            </div>
          }
        </div>
        <div className="flex px-[20px] pb-[38px]" onMouseLeave={()=>{setVisibility(true)}}>
        <button data-dropdown-placement="bottom"  className="text-white bg-[#ffffff1c]  focus:outline-none   font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-[#ffffff1c] hover:bg-[#ffffff1c] focus:ring-blue-800" type="button"  onClick={()=>{setVisibility(!visibility)}}  >{displayChain} <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
          <div id="dropdownUsers"  className={` absolute mt-[55px] mr-1   ${visibility === true ? "hidden" : ""}  bg-[#ffffff1c] rounded-lg shadow w-30 bg-[#ffffff1c]`}>
              <ul className="h-17 overflow-y-auto text-gray-700   text-gray-200"   >
                <li>
                  <button  className="flex  w-full rounded px-2 hover:bg-[#ffffff1a] hover:text-white  items-center px-4 py-2 " value="Solana" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={SolanaLogo} alt="Solana Image" />
                    Solana
                  </button>
                </li>
                <li>
                  <button  className="flex w-full rounded px-2 items-center px-4 py-2 hover:bg-[#ffffff1a] hover:text-white" value="Ethereum" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={EthLogo} alt="Ethereum Image" />
                    Ethereum
                  </button>
                </li>
              </ul>
            </div>
          <input
            onChange={handleUserInputChange}
            value={userInput}
            onKeyDown={handleKeypress}
            type="text"
            className="bg-[#ffffff1c] text-[#efefefcc] px-[18px] py-[12px] rounded-[4px] w-full h-[50px] mx-[16px]"
            placeholder={placeholder}
          />

          <button
            disabled={
              userInput === "" || chain === "" || isLoading ? true : false
            }
            onClick={handleSubmit}
            className="colored h-[50px] w-[85px] flex justify-center items-center rounded-[4px] bg-gradient-to-r from-[#F55151] to-[#FFB800]"
          >
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <img src={SubmitButtonSvg} alt="" />
            )}
          </button>
        </div>
      </div>
      {errorState === true ? <Error error={error} /> : ""}
      {mode === "domain to owner" &&
        Object.keys(result).length !== 0 &&
        result.wallet ? (
        <>
          <div className="relative overflow-x-auto mt-[20px] rounded">
            <div className="bg-[#0000006e] text-left text-[#575757] px-[16px] pt-[22px] pb-[28px]">Owner</div>
            <div className="bg-[#0000006e] flex items-center justify-start px-[16px] pb-[31px]">
              <div className="font-normal text-[#efefefcc]">
                {result.wallet === undefined
                  ? "Domain not Registered"
                  : result.wallet}
              </div>
              {!isCopied ? (
                <img
                  onClick={() => {
                    copyTextToClipboard(result.wallet);
                    handleCopy();
                  }}
                  className="cursor-pointer pl-[10px]"
                  src={copyImg}
                  alt=""
                />
              ) : (
                <img width="3%" className="pl-[10px]" src={copied} alt="" />
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {mode === "wallet to domains" &&
        result["domains"] !== undefined &&
        result["domains"].length !== 0 ? (
        <>
          <div className="bg-[#0000006e] mt-[20px] rounded">
            <div className="text-[16px] font-normal text-[#575757] text-left px-[16px] pt-[22px] pb-[28px]">
              {`Domains Owned (${result['domains'].length})`}
            </div>
            {result.domains.map((value: any, index: number) => {
              return (
                <div className="px-[16px] pb-[22px] flex justify-start items-center">
                  <p className="text-[16px] text-[#efefefcc]">{value}</p>
                  {index !== copyItemIndex ? (
                    <img
                      width="3%"
                      onClick={() => copyTextToClipboard(value, index)}
                      className="cursor-pointer pl-[10px]"
                      src={copyImg}
                      alt=""
                    />
                  ) : (
                    <img width="3%" className="pl-[10px]" src={copied} alt="" />
                  )}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default DomainResolver;
