import React from "react";
import { Outlet } from "react-router-dom";
// import Footer from "./Footer";
import Navbar from "../Components/Playground/NavBar/NavBar";
import BackgroundImg from "../assets/playground_bg.jpg";
import QueryEngine from "../Components/QueryEnginer/QueryEngine";
import Landing from "./Landing";

function Layout(props: any) {
  // console.log("prosp in lay", props);
  const { tab } = props;
  return (
    <div
      style={{ backgroundImage: `url(${BackgroundImg}`, backgroundSize: "cover" }}
      className="App flex flex-center h-[calc(100%)] min-h-screen flex-col items-center"
    >
      <Navbar {...props} />
      {/* {tab === "/" ? <QueryEngine /> :
        <Outlet {...props} className="container" />} */}
      {tab === "/" ? <Landing {...props} /> :
        <Outlet {...props} className="container" />}
    </div>
  );
}

export default Layout;
