import React, { useState } from "react";
import nftCollectionBG from "../../../assets/nft-collection.png";
import DisplayNft from "./DisplayNft/DisplayNft";
import Error from "./Error/Error";
import { getNFTCollection } from "../../../../../apiHelper";
import CircularProgress from "@mui/material/CircularProgress";
import test from "../../../assets/test.png";
import copyImg from "../../../assets/copy.png";
import DisplayNFTCollection from "./DisplayNFTCollection/DisplayNFTCollection";
import SubmitButtonSvg from "../../../assets/submitButton.png";
import Previous from "../../../assets/back.png";
import Next from "../../../assets/next.png";
import EthLogo from "../../../assets/EthLogo.png";
import SolanaLogo from "../../../assets/SolanaLogo.png";

function NftCollection(props: any) {
  const [pageNo, setPageNo] = useState<string>("1");
  const { currentUser, renderConfetti } = props;
  const [isLoading, setLoading] = useState(false);
  const [chain, setChain] = useState("Ethereum");
  const [displayChain, setDisplayChain] = useState("");
  const [contractAddress, setContractAddress] = useState(
    "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D"
  );
  const [data, setData] = useState<any>(null);
  const [visibility, setVisibility] = useState<boolean>(true)
  const [owner, setOwner] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [error, setError] = useState<any>(false);

  const handleChainChange = (e: any) => {
    const { value } = e.currentTarget;
    setChain(value);
  };
  const handleWalletChange = (e: any) => {
    const { value } = e.currentTarget;
    setContractAddress(value);
  };

  const handleImageUrl = (url: string) => {
    if (url && url.startsWith("ipfs")) {
      url = `https://gateway.pinata.cloud/ipfs/${url.replace("ipfs://", "")}`;
    }
    return url;
  };

  const handleError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 7000);
  };

  async function copyTextToClipboard(value: any) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(value);
    } else {
      return document.execCommand("copy", true, value);
    }
  }

  const handleSubmit = async (pageNo: any) => {
    try {
      if (chain !== "" && contractAddress !== "") {
        setLoading(true);
        const res = await getNFTCollection(
          contractAddress,
          currentUser.apiToken,
          pageNo
        );
        if (res.errorMessage) {
          setData(null);
          setLoading(false);
          setError(true)
          return
        }
        setDisplayChain(chain);

        if (res.errorMessage) {
          setLoading(false);
          handleError();
          setLoading(false);
          setData(null);
          return;
        }

        renderConfetti();
        setData(res);
        setOwner(res["0"]["owner"]);
        setName(res["0"]["name"]);
        setSymbol(res["0"]["symbol"]);
        setLoading(false);
      } else {
        console.log("please enter chain and wallet");
        handleError();
      }
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
    return;
  };
  const handleKeypress = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmit(pageNo);
    }
  };
  return (
    <>
      <div className="bg-[#0000006e] w-full rounded">
        <div className="text-[20px] px-[20px] text-left font-[500] pt-[20px] text-[#C8C8C8]">
          Fetch NFT Collection Info
        </div>
        <div className="flex px-[20px] mt-[19px] pb-[38px]" onMouseLeave={()=>{setVisibility(true)}}>
        <button data-dropdown-placement="bottom"  className="text-white bg-[#ffffff1c]  focus:outline-none   font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-[#ffffff1c] hover:bg-[#ffffff1c] focus:ring-blue-800" type="button"  onClick={()=>{setVisibility(!visibility)}}  >Ethereum <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
          <div id="dropdownUsers"  className={` absolute mt-[55px] mr-1   ${visibility === true ? "hidden" : ""}  bg-[#ffffff1c] rounded-lg shadow w-30 bg-[#ffffff1c]`}>
              <ul className="h-17 overflow-y-auto text-gray-700   text-gray-200"   >
                <li>
                  <button  className="flex cursor-not-allowed text-neutral-400 w-full rounded px-2  items-center px-4 py-2 " value="Solana" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={SolanaLogo} alt="Solana Image" />
                    Solana
                  </button>
                </li>
                <li>
                  <button  className="flex w-full rounded px-2 items-center px-4 py-2 hover:bg-[#ffffff1a] hover:text-white" value="Ethereum" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={EthLogo} alt="Ethereum Image" />
                    Ethereum
                  </button>
                </li>
              </ul>
            </div>
          <input
            onChange={handleWalletChange}
            value={contractAddress}
            onKeyDown={handleKeypress}
            type="text"
            className="bg-[#ffffff1c] text-[#efefefcc] text-[16px] px-[18px] py-[12px] rounded-[4px] w-full h-[50px] mx-[16px]"
            placeholder="Contract Address"
          />
          <button
            disabled={isLoading}
            onClick={() => handleSubmit(1)}
            className="colored h-[50px] w-[85px] flex justify-center items-center rounded-[4px] bg-gradient-to-r from-[#F55151] to-[#FFB800]"
          >
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <img src={SubmitButtonSvg} alt="" />
            )}
          </button>
        </div>
      </div>
      {error === true ? <Error /> : ""}
      {data !== null && owner && name && symbol ? (
        <>
          {/* <div className="flex justify-between mt-[20px]"> */}
          <div className="flex justify-evenly mt-[20px]">
            <div className="bg-[#0000006e] w-[299px] h-[95px] flex flex-col items-center justify-center rounded">
              <p className="px-[27px] pt-[18px] text-[20px]  pb-[4px] font-[500]">
                {data['0']['name']}
              </p>
              <p className="px-[27px] pb-[18px] pb-[18px] text-[16px] text-[#575757] font-[400]">
                Collection Name
              </p>
            </div>
            {/* <div className="bg-[#0000006e] w-[299px] h-[95px] flex flex-col items-center justify-center mx-[20px] rounded">
              <p className="px-[27px] pt-[18px] text-[20px] pb-[4px] font-[500]">
                Need to change
              </p>
              <p className="px-[27px] pb-[18px] pb-[18px] text-[16px] text-[#575757] font-[400]">
                Collection Size
              </p>
            </div> */}
            <div className="bg-[#0000006e] w-[299px] h-[95px] flex flex-col items-center justify-center rounded">
              <p
                className="px-[27px] pt-[18px] text-[20px]  pb-[4px] font-[500]"
                onClick={copyTextToClipboard}
              >{data['0']['symbol']}</p>
              <p className="px-[27px] pb-[18px] pb-[18px] text-[16px] text-[#575757] font-[400]">
                Symbol
              </p>
            </div>
          </div>
          <div className="mt-[20px] grid grid-cols-3 gap-[20px]">
            <DisplayNFTCollection
              data={data}
              handleImageUrl={handleImageUrl}
              displayChain={displayChain}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {data === null ? (
        ""
      ) : (
        <div className="flex justify-end items-center mt-[20px] pb-[20px] gap-[14px]">
          {
            <button
              className="h-[48px] w-[85px] bg-[#ffffff1c] flex items-center justify-center rounded-[4px]"
              disabled={!(data[5] && data[5]["previousPage"])}
              onClick={() => {
                setPageNo(data[5]["previousPage"]);
                handleSubmit(data[5]["previousPage"]);
              }}
            >
              <img src={Previous} />
              {/* {isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                <img src={Previous} />
              )} */}
            </button>
          }
          <button
            className="h-[48px] w-[85px] bg-[#ffffff1c] flex items-center justify-center rounded-[4px]"
            disabled={!(data[5] && data[5]["nextPage"])}
            onClick={() => {
              setPageNo(data[5]["nextPage"]);
              handleSubmit(data[5]["nextPage"]);
            }}
          >
            <img src={Next} />
            {/* {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <img src={Next} />
            )} */}
          </button>
        </div>
      )}
    </>
  );
}

export default NftCollection;
