const functionSrc =
  "https://us-central1-appclweb.cloudfunctions.net/clFunctionsNew";

const getWhitelistDataNewCF = async (wallet: string) => {
  return await fetch(`${functionSrc}/getWhitelistDataNew`, {
    headers: {
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ wallet }),
  }).then((resp) => resp.json());
};

const getWhitelistDataStarknet = async (wallet: string) => {
  return await fetch(`${functionSrc}/getStarknetWhitelistData`, {
    headers: {
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ wallet }),
  }).then((resp) => resp.json());
};

const getWhitelistDataPolygon = async (wallet: string) => {
  return await fetch(`${functionSrc}/getPolygonWhitelistData`, {
    headers: {
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ wallet }),
  }).then((resp) => resp.json());
};

const getOnboardUrlSolana = (env: string = "mainnet") => {
  switch (env) {
    case "mainnet": {
      return `${functionSrc}/onboardProgram`;
    }
    case "devnet": {
      return `${functionSrc}/onboardProgramDevnet`;
    }
    default: {
      return `${functionSrc}/onboardProgram`;
    }
  }
};

const getOnboardUrlPolygon = (env: string = "mainnet") => {
  switch (env) {
    case "mainnet": {
      return `${functionSrc}/onboardProgramPolygon`;
    }
    case "devnet": {
      return `${functionSrc}/onboardProgramPolygonTestnet`;
    }
    default: {
      return `${functionSrc}/onboardProgramPolygon`;
    }
  }
};

const postInitOnboarding = async (
  formvalues: any,
  walletAddress: any,
  chain: string
) => {
  let onboardUrl: string;
  console.log(formvalues);
  switch (chain) {
    case "Starknet":
      onboardUrl = `${functionSrc}/onboardStarknetContract`;
      break;
    case "Polygon":
      onboardUrl = getOnboardUrlPolygon(formvalues.env);
      break;
    default:
      onboardUrl = getOnboardUrlSolana(formvalues.env);
  }

  console.log("onb url", onboardUrl);
  return await fetch(onboardUrl, {
    headers: {
      "X-Firebase-AppCheck": "appCheckTokenResponse.token",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ ...formvalues, walletAddress }),
  }).then((resp) => resp.json());
};

const getUserDetails = async (userId: any) => {
  return await fetch(`${functionSrc}/getUserDetails`, {
    headers: {
      "X-Firebase-AppCheck": "appCheckTokenResponse.token",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ userId }),
  }).then((resp) => resp.json());
};

const updateUserDetails = async (
  uid: string,
  email: string,
  usageData: any
) => {
  return await fetch(`${functionSrc}/updateUserDetails`, {
    headers: {
      "X-Firebase-AppCheck": "appCheckTokenResponse.token",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ uid, email, usageData }),
  }).then((resp) => resp.json());
};

const createApiToken = async (userId: string) => {
  return await fetch(`${functionSrc}/createApiToken`, {
    headers: {
      "X-Firebase-AppCheck": "appCheckTokenResponse.token",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ userId }),
  }).then((resp) => resp.json());
};

const getSolPortFolio = async (apiToken: string, wallet: string) => {
  return await fetch(
    `${functionSrc}/api/v0/solana-mainnet/portfolio/getportfolio/${wallet}/${apiToken}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  ).then((resp) => resp.json());
};

// solana naming service
const getAllDomainOfWallet = async (
  wallet: string,
  token: string,
  network: string
) => {
  // /api/v0/:network/naming-service/getalldomain/:wallet/:apiToken
  return await fetch(
    `${functionSrc}/api/v0/${network}/naming-service/getalldomains/${wallet}/${token}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  ).then((resp) => resp.json());
};

const getWalletOfADomain = async (
  domain: string,
  token: string,
  network: string
) => {
  // /api/v0/:network/naming-service/getalldomain/:wallet/:apiToken
  return await fetch(
    `${functionSrc}/api/v0/${network}/naming-service/getaddress/${domain}/${token}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  ).then((resp) => resp.json());
};

const getNFTMetaDataEth = async (
  contract: string,
  nftId: number,
  token: string
) => {
  return await fetch(
    `${functionSrc}/api/v0/nfts/ethereum-mainnet/${contract}/${nftId}/metadata/${token}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  ).then((resp) => resp.json());
};

const getNFTMetaDataSolana = async (contract: string, token: string) => {
  return await fetch(
    `${functionSrc}/api/v0/nfts/solana-mainnet/${contract}/metadata/${token}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  ).then((resp) => resp.json());
};

const getNFTCollection = async (
  contract: string,
  token: string,
  pageNo: number = 1,
  network: string = "ethereum-mainnet"
) => {
  return await fetch(
    `${functionSrc}/api/v0/nfts/${network}/${contract}/all/${token}?pageNo=${pageNo}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  ).then((resp) => resp.json());
};




export {
  getWhitelistDataNewCF,
  postInitOnboarding,
  getUserDetails,
  updateUserDetails,
  createApiToken,
  getSolPortFolio,
  getNFTCollection,
  getNFTMetaDataEth,
  getNFTMetaDataSolana,
  getAllDomainOfWallet,
  getWalletOfADomain,
  getWhitelistDataStarknet,
  getWhitelistDataPolygon,
};
