import { useState } from "react";
import copyImg from "../../../../assets/copy.png";
import copied from "../../../../assets/copied.png";

function DisplayNft(props: any) {
  const {
    contractAddress,
    name,
    symbol,
    imageUrl,
    chain,
    copyTextToClipboard,
    metadata,
  } = props;
  const [isCopied, setisCopied] = useState(false);

  const handleCopy = () => {
    setisCopied(true);
    setTimeout(() => {
      setisCopied(false);
    }, 2000);
  };
  return (
    <div className="grid grid-cols-2 gap-[21px] mt-[20px]">
      <div className="bg-[#0000006e] flex justify-center items-center p-[27px] rounded">
        <img src={imageUrl} className="rounded" />
      </div>
      {chain === 'Solana' ?
        <div className="bg-[#0000006e] text-left px-[18px] py-[30px] rounded">
          <div>
            <p className="text-[20px] text-[#C8C8C8] pb-[22px]">{name}</p>
          </div>
          <div>
            <div className="flex justify-start items-center pb-[8px]">
              <p className="text-[16px] w-[151px] text-[#575757]">Collection Name</p>
              <p className="text-[16px] text-[#efefefcc] ml-[17px] font-[500]">{metadata.metadata.collection.name}</p>
            </div>
            <div className="flex justify-start items-center pb-[8px]">
              <p className="text-[16px] w-[151px] text-[#575757]">Family</p>
              <p className="text-[16px] text-[#efefefcc] ml-[17px] font-[500]">{metadata.metadata.collection.family}</p>
            </div>
            <div className="flex justify-start items-center pb-[8px]">
              <p className="text-[16px] w-[151px] text-[#575757]">Symbol</p>
              <p className="text-[16px] text-[#efefefcc] ml-[17px] font-[500]">{metadata.metadata.symbol}</p>
            </div>
            <div className="flex justify-start items-center pb-[8px]">
              <p className="text-[16px] w-[151px] text-[#575757]">Creater</p>
              <p className="text-[16px] text-[#efefefcc] ml-[17px] font-[500]">{`${metadata['metadata']['properties']['creators']['0']['address'].slice(0, 4)}...${metadata['metadata']['properties']['creators']['0']['address'].slice(-4)}`}</p>
              {isCopied ?
                <img src={copied} width="5%" className="mx-[5px]" />
                :
                <img src={copyImg} onClick={handleCopy} className="mx-[10px] cursor-pointer" />
              }
            </div>
          </div>
        </div>
        :
        <div className="bg-[#0000006e] text-left px-[18px] py-[30px] rounded">
          <div>
            <p className="text-[20px] text-[#C8C8C8] pb-[22px]">{name}</p>
          </div>
          <div>
            {/* <div className="flex justify-start items-center pb-[8px]">
            <p className="text-[16px] w-[151px] text-[#575757]">Collection Name</p>
            <p className="text-[16px] text-[#efefefcc] ml-[17px]">{metadata.metadata.collection.name}</p>
          </div> */}
            {/* <div className="flex justify-start items-center pb-[8px]">
            <p className="text-[16px] w-[151px] text-[#575757]">Family</p>
            <p className="text-[16px] text-[#efefefcc] ml-[17px]">{metadata.metadata.collection.family}</p>
          </div> */}
            <div className="flex justify-start items-center pb-[8px]">
              <p className="text-[16px] w-[151px] text-[#575757]">Symbol</p>
              <p className="text-[16px] text-[#efefefcc] ml-[17px] font-[500]">{metadata.symbol}</p>
            </div>
            <div className="flex justify-start items-center pb-[8px]">
              <p className="text-[16px] w-[151px] text-[#575757]">Owner</p>
              <p className="text-[16px] text-[#efefefcc] ml-[17px] font-[500]">{`${metadata['owner'].slice(0, 4)}...${metadata['owner'].slice(-4)}`}</p>
              {isCopied ?
                <img src={copied} width="5%" className="mx-[5px]" />
                :
                <img src={copyImg} onClick={handleCopy} className="mx-[10px] cursor-pointer" />
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default DisplayNft;
