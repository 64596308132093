import React from 'react'
import { BounceLoader } from 'react-spinners';

type Props = {}

const OnboardingProgress = (props: Props) => {
  return (
<>
<div className='h-24 mt-4 content-center pl-8 w-full grid grid-cols-[60%_40%] bg-gradient-to-r from-[#F55151] to-[#FFB800] rounded'>
  <div className="flex flex-col text-left pl-2">
    <div className="font-[700] text-[16px] text-[#FFFFFF]">Backfill is in progress</div>
    <div className="font-light text-[#FFFFFF] font-[400] text-[14px]">You may start using the API, but you get the accurate data post backfill completion. We will send an email after completion</div>
  </div>
  <div className="content-center pt-7 pl-10">
    <div className="text-[13px] text-[#FFFFFF] flex justify-end pr-16 pb-2">This typically take 5-10mins</div>
    <div className='flex flex-row justify-center'>
      <BounceLoader size={12} className='mr-1' />
      <BounceLoader size={12} className='mr-1' />
      <BounceLoader size={12} />
    </div>
  </div>
</div>
</>
  )
}

export default OnboardingProgress