import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, SafeEventEmitterProvider } from "@web3auth/base";
import { SolanaWallet } from "@web3auth/solana-provider";
import Web3 from "web3";
import ClLogo from "../../assets/CL-Logo-Transparent.png";
//@ts-ignore
import starkwareCrypto from "@starkware-industries/starkware-crypto-utils";

const clientId =
  "BEqHjdimDUs_r-jQcGH_B2NIEIqde9FKLBjZ5Ckk0I2if9y-v1LtYoTJ28torlG2OsiTyOQmMDs0gEawccGAwVo"; // get from https://dashboard.web3auth.io
let web3AuthSol: any;
let web3AuthEth: any;
let web3AuthStark: any;

const initWeb3AuthSol = () => {
  if (!web3AuthSol) {
    web3AuthSol = new Web3Auth({
      clientId, // get it from Web3Auth Dashboard
      web3AuthNetwork: "cyan",
      chainConfig: {
        chainNamespace: "solana",
        chainId: "0x1", // Please use 0x1 for Mainnet, 0x2 for Testnet, 0x3 for Devnet
        rpcTarget:
          "https://billowing-small-bush.solana-mainnet.quiknode.pro/a96cf4f1ad2eba10c8a716ee7f5cd72bc0798b47",
        displayName: "Solana Mainnet",
        blockExplorer: "https://explorer.solana.com",
        ticker: "SOL",
        tickerName: "Solana Token",
      },
      uiConfig: {
        theme: "dark",
        loginMethodsOrder: ["google"],
        appLogo: ClLogo, // Your App Logo Here
      },
    });
  }
  return web3AuthSol;
};

const initWeb3AuthEth = () => {
  if (!web3AuthEth) {
    web3AuthEth = new Web3Auth({
      clientId, // get it from Web3Auth Dashboard
      web3AuthNetwork: "cyan",
      chainConfig: {
        chainNamespace: "eip155",
        chainId: "0x1",
        rpcTarget:
          "https://celo-mainnet.infura.io/v3/61a321aeeb054601b7e2a6e11f06d93b",
        displayName: "Ethereum Mainnet",
        blockExplorer: "https://etherscan.io",
        ticker: "ETH",
        tickerName: "Ethereum",
      },
      uiConfig: {
        theme: "dark",
        loginMethodsOrder: ["google"],
        appLogo: ClLogo, // Your App Logo Here
      },
    });
  }
  return web3AuthEth;
};

const initWeb3AuthStark = () => {
  if (!web3AuthStark) {
    web3AuthStark = new Web3Auth({
      clientId, // get it from Web3Auth Dashboard
      web3AuthNetwork: "cyan",
      chainConfig: {
        chainNamespace: "other",
        rpcTarget:
          "https://starknet-mainnet.infura.io/v3/73739b9dba9b4d849687e61cfdc34af5",
        displayName: "Starknet Mainnet",
        blockExplorer: "https://starkscan.co/",
      },
      uiConfig: {
        theme: "dark",
        loginMethodsOrder: ["google"],
        appLogo: ClLogo, // Your App Logo Here
      },
    });
  }
  return web3AuthStark;
};

const initWeb3AuthGmail = () => {
  if (!web3AuthSol) {
    web3AuthSol = new Web3Auth({
      clientId, // get it from Web3Auth Dashboard
      web3AuthNetwork: "cyan",
      chainConfig: {
        chainNamespace: "other",
        chainId: "0x1", // Please use 0x1 for Mainnet, 0x2 for Testnet, 0x3 for Devnet
        rpcTarget:
          "https://billowing-small-bush.solana-mainnet.quiknode.pro/a96cf4f1ad2eba10c8a716ee7f5cd72bc0798b47",
        displayName: "Solana Mainnet",
        blockExplorer: "https://explorer.solana.com",
        ticker: "SOL",
        tickerName: "Solana Token",
      },
      uiConfig: {
        theme: "dark",
        loginMethodsOrder: ["google"],
        appLogo: ClLogo, // Your App Logo Here
      },
    });
  }
  return web3AuthSol;
};

const getStarknetAddress = async (web3authProviderStark: any) => {
  const privateKey = await web3authProviderStark.request({
    method: "private_key",
  });
  const keyPair = starkwareCrypto.ec.keyFromPrivate(privateKey, "hex");
  // get the account address from the public key
  const account = starkwareCrypto.ec.keyFromPublic(
    keyPair.getPublic(true, "hex"),
    "hex"
  );

  // Use this key for the StarkNet transactions
  const startKey = account.pub.getX().toString("hex");

  return startKey;
};

export const logout = async (chain: string) => {
  if (chain === "Solana") {
    const web3AuthSol = initWeb3AuthSol();

    if (!web3AuthSol) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3AuthSol.logout();
  } else if (chain === "Ethereum") {
    const web3AuthEth = initWeb3AuthEth();
    if (!web3AuthEth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3AuthEth.logout();
  } else if (chain === "Starknet") {
    const web3AuthStark = initWeb3AuthStark();
    if (!web3AuthStark) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3AuthEth.logout();
  } else {
    const web3AuthSol = initWeb3AuthSol();

    if (!web3AuthSol) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3AuthSol.logout();
  }
};

export const initilize = async (chain: string) => {
  switch (chain) {
    case "Solana":
      const web3AuthSol = initWeb3AuthSol();
      await web3AuthSol.initModal();
      const web3authProvidersol = await web3AuthSol.connect();
      const solanaWallet = new SolanaWallet(web3authProvidersol);
      const accounts = await solanaWallet.requestAccounts();
      const userSol = await web3AuthSol.getUserInfo();
      const resSol = {
        userInfo: userSol,
        wallet: accounts[0],
      };
      return resSol;

    case "Ethereum":
      const web3AuthEth = initWeb3AuthEth();
      await web3AuthEth.initModal();
      const web3authProviderEth = await web3AuthEth.connect();
      const web3 = new Web3(web3authProviderEth);
      const address = (await web3.eth.getAccounts())[0];
      const userEth = await web3AuthEth.getUserInfo();
      const resEth = {
        userInfo: userEth,
        wallet: address,
      };
      return resEth;

    case "Starknet":
      const web3AuthStark = initWeb3AuthStark();
      console.log(web3AuthStark);
      await web3AuthStark.initModal();
      const web3authProviderStark = await web3AuthStark.connect();
      const web3Stark = new Web3(web3authProviderStark);
      const starkAddress = getStarknetAddress(web3authProviderStark);
      const userStark = await web3AuthStark.getUserInfo();
      const resStark = {
        userInfo: userStark,
        wallet: starkAddress,
      };
      return resStark;

    case "Solana-Social":
      const web3AuthSol_ss = initWeb3AuthSol();
      await web3AuthSol_ss.initModal();
      const web3authProvidersol_ss = await web3AuthSol_ss.connect();
      const solanaWallet_ss = new SolanaWallet(web3authProvidersol_ss);
      const accounts_ss = await solanaWallet_ss.requestAccounts();
      const userSol_ss = await web3AuthSol_ss.getUserInfo();
      const resSol_ss = {
        userInfo: userSol_ss,
        wallet: accounts_ss[0],
      };
      return resSol_ss;

    default:
      console.log("Please select correct chain");
      break;
  }
};
