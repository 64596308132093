import { useEffect, useState } from "react";
import Whitelisted from "../Whitelisted/Whitelisted";
import {
  getWhitelistDataNewCF,
  postInitOnboarding,
  getWhitelistDataStarknet,
  getWhitelistDataPolygon,
} from "../../apiHelper";
import { BounceLoader } from "react-spinners";
import OnboardingForm from "../OnboardingForm/OnboardingForm";
import StarknetOnboardingForm from "../OnboardingForm/StarknetOnboardingForm";
import PolygonOnboardingForm from "../OnboardingForm/StarknetOnboardingForm";

import ProjectDetails from "../ProjectDetails/ProjectDetails";
import OnboardingProgress from "../OnboardingProgress/OnboardingProgress";

import "./WalletConnected.css";
import NotWhitelisted from "../NotWhitelisted/NotWhitelisted";
import StarknetProjectDetails from "../ProjectDetails/StarknetProjectDetails";

type WhitelistData = {
  program_name: string;
  program_id: string;
  is_whitelisted: boolean;
  has_applied: boolean;
  project_data: Record<string, any>;
  project_metrics: Record<string, any>;
};

function WalletConnected(props: any) {
  const { chain, walletAddress, email } = props;

  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);
  const [whitelistData, setWhitelistData] = useState<WhitelistData>();
  const [isLoading, setIsLoading] = useState(true);
  const [showOnboardingProgress, setShowOnboardingProgress] = useState(false);
  const [programName, setProgramName] = useState("");
  const [programId, setProgramId] = useState("");

  // const { publicKey } = useWallet();
  const checkIfWhitelisted = async (publicKey: string) => {
    setIsLoading(true);
    try {
      if (chain === "Starknet") {
        const { data } = await getWhitelistDataStarknet(publicKey);
        setWhitelistData(data);
        setIsWhitelisted(data.whitelist_status === "whitelisted");
        setHasApplied(!!data.whitelist_status);
        setIsLoading(false);
      } else if (chain === "Polygon") {
        const { data } = await getWhitelistDataPolygon(publicKey);
        setWhitelistData(data);
        setIsWhitelisted(data.whitelist_status === "whitelisted");
        setHasApplied(!!data.whitelist_status);
        setIsLoading(false);
      } else {
        const { data } = await getWhitelistDataNewCF(publicKey);
        setWhitelistData(data);
        setIsWhitelisted(data.whitelist_status === "whitelisted");
        setHasApplied(!!data.whitelist_status);
        setIsLoading(false);
      }
    } catch (e) {
      // const checkIfWhitelisted = async (publicKey: PublicKey) => {
      //   setIsLoading(true);
      //   try {
      //     const { data } = await getWhitelistDataNewCF(publicKey.toBase58());
      //     setWhitelistData(data);
      //     setIsWhitelisted(data.whitelist_status === 'whitelisted');
      //     setHasApplied(!!data.whitelist_status);
      //     setIsLoading(false);
      //   } catch (e) {
      // for local development purposes
      /*
      setWhitelistData({
        is_whitelisted: true, 
        has_applied: true, 
        project_data: {
          endpoint_api: 'https://wordcel.conciselabs.io/v1/graphql',
          admin_secret: 'BqtN7EEkmIS8VN7lyNU0uh7eNCfZqBK57I32Xf8wwAyyf2u0VW1R2nQD2n3Wj481'
        }, 
        project_metrics: {
          last_updated_on: 1659694068,
          last_one_day: 12,
          last_one_hr: 3
        }, 
        program_id: 'EXzAYHZ8xS6QJ6xGRsdKZXixoQBLsuMbmwJozm85jHp', 
        program_name: 'Wordcel'
      });
      setIsWhitelisted(true);
      setHasApplied(true);
      */
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAddress) {
      console.log("wallet in use", walletAddress);
      checkIfWhitelisted(walletAddress);
    }
  }, [walletAddress]);

  const renderOnboardingForm = (chain: string) => {
    switch (chain) {
      case "Starknet": {
        return (
          <StarknetOnboardingForm setFormData={initOnboarding} {...props} chain={chain} />
        );
      }
      case "Polygon": {
        return (
          <PolygonOnboardingForm setFormData={initOnboarding} {...props} chain={chain} />
        ); // TODO:-  add polygon onboarding form
      }
      default: {
        return <OnboardingForm {...props} setFormData={initOnboarding} />;
      }
    }
  };

  const renderProjectData = (chain: string) => {
    switch (chain) {
      case "Starknet": {
        return (
          <StarknetProjectDetails
            name={programName}
            programId={programId}
            chain={chain}
          />
        );
      }
      case "Polygon": {
        return (
          <StarknetProjectDetails
            name={programName}
            programId={programId}
            chain={chain}
          />
        ); // add Polygon project details
      }
      default: {
        <ProjectDetails name={programName} programId={programId} />;
      }
    }
  };

  const initOnboarding = async (formValues: any) => {
    setProgramId(formValues.programId);
    setProgramName(formValues.programName);
    setShowOnboardingProgress(true);

    try {
      // const walletAddress = walletAddress;
      let res;
      if (!email) {
        // console.log("asdf", walletAddress)
        res = await postInitOnboarding(formValues, walletAddress, chain);
      } else {
        // console.log("Sdfghh", email)
        res = await postInitOnboarding(formValues, email, chain);
      }

      const data = res.data;
      if (res.type === "success" && data.type === "success") {
        setIsWhitelisted(true);
        setHasApplied(true);

        const whiteListData: any = {};
        whiteListData.project_name = data.data.project_name;
        whiteListData.program_id = programId;
        whiteListData.project_data = {
          endpoint_api: data.data.playgroundUrl,
          admin_secret: data.data.admin_secret,
        };

        setWhitelistData(whiteListData);
      } else if (res.type === "error" || data.type === "error") {
        setHasApplied(true);
      }
      setShowOnboardingProgress(false);
    } catch (e) {
      // for local development purpose
    }
  };

  return (
    <div className="walletconnected__container px-[90px]">
      {isLoading && (
        <div className="walletconnected__loader">
          <BounceLoader
            size={70}
            color="#ff7a00"
            loading={true}
            speedMultiplier={1.2}
          />
        </div>
      )}
      {programName && programId ? renderProjectData(chain) : ""}
      {showOnboardingProgress && <OnboardingProgress />}
      {!isLoading && isWhitelisted && whitelistData && (
        <div className="mt-[80px]">
          <Whitelisted
            name={whitelistData.program_name}
            programId={whitelistData.program_id}
            endpointApi={whitelistData.project_data.endpoint_api}
            adminSecret={whitelistData.project_data.admin_secret}
            projectMetrics={whitelistData.project_metrics || {}}
          />
        </div>
      )}
      {!isLoading &&
        !isWhitelisted &&
        !showOnboardingProgress &&
        (hasApplied ? <NotWhitelisted /> : renderOnboardingForm(chain))}
    </div>
  );
}

export default WalletConnected;
