import React from "react";
import { getTruncatedPubkey } from "../../utils";
import "./ProjectDetails.css";

type ProjectDetailsProps = {
  name: string;
  programId: string;
  chain: string;
};

function StarknetProjectDetails(props: ProjectDetailsProps) {
  return (
    <>
      <div className="project__container p-4 sm:p-0 flex flex-col sm:flex-row place-self-start">
        <div className="project__name">{props.name}</div>
        <div className="project__programid text-ellipsis overflow-hidden w-[300px] sm:w-auto text-left">
          Contract Address:{" "}
          <a
            href={`https://starkscan.co/contract/${props.programId}`}
            target={"_blank"}
            className="project_linkid"
            rel="noreferrer"
          >
            {props.programId ? getTruncatedPubkey(props.programId) : ""}
          </a>
        </div>
      </div>
    </>
  );
}

export default StarknetProjectDetails;
