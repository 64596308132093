import { useState, useEffect } from "react";
import signInBG from "../../assets/signInBG.png";
import lineBreak from "../../assets/lineBreak.png";
import Close from "../../assets/close.png";
import SolanaLogo from "../../assets/Solana.png";
import starknetLogo from "../../assets/starknet.png";
import polygonLogo from "../../assets/Polygon.png";
import { initilize } from "../ChooseChain/loginUtilities";
import { BounceLoader } from "react-spinners";

function UserSignIn(props: any) {
  const [isLoading, setIsLoading] = useState<any>(false);
  const {
    setWalletAddress,
    setEmail,
    setChooseChain,
    setWantToLogin,
    status,
    setGoogleAuthResp,
    chooseChain,
    walletAddress,
  } = props;

  useEffect(() => {
    document.addEventListener("click", function (e: any) {
      const target = e.target.closest(".w3ajs-close-btn"); // closing web3 modal
      if (target) {
        window.location.replace("/api");
      }
    });
  }, []);

  const handleButtonClick = async (chain: string) => {
    if (chain === "Solana" || chain === "Ethereum" || chain === "Ethereum") {
      document.body.classList.add("web3");
    } else {
      document.body.classList.remove("web3");
      document.body.classList.add("social");
    }
    setIsLoading(true);
    try {
      const res: any = await initilize(chain);
      setGoogleAuthResp(res);
      setWalletAddress(res.wallet);
      // alert(`res.wallet : ${res.wallet}, walletAddress: ${walletAddress}`)
      setEmail(res.userInfo.email);
    } catch (error) {
      setIsLoading(false);
      console.log("something went wrong with web3Auth", error);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="walletconnected__loader">
          <BounceLoader
            size={70}
            color="#ff7a00"
            loading={true}
            speedMultiplier={1.2}
          />
        </div>
      ) : (
        <div className="relative h-[482px] w-[402px] rounded flex justify-center items-center mt-[101px] bg-gradient-to-r from-[#F55151] to-[#FFB800] shadow-inherit">
          <span
            onClick={() => {
              setWantToLogin(false);
            }}
            className="absolute mt-[-436px] ml-[360px] cursor-pointer"
          >
            <img src={Close} />
          </span>
          <div
            className="h-[480px] w-[400px] rounded"
            style={{
              backgroundImage: `url(${signInBG}`,
              backgroundSize: "cover",
              fontFamily: "poppins",
            }}
          >
            <div className="px-[40px] pb-[33px] text-left">
              <div className="mt-[33px] text-[24px] font-[500]">{status}</div>
              <div className="mt-[8px] text-[#efefefcc] text-[16px]">
                Connect with your wallet or social
              </div>
              <div className="mt-[17px]">
                <button
                  onClick={() => {
                    handleButtonClick("Solana");
                    setChooseChain("Solana");
                  }}
                  className="flex justify-start px-[28px] items-center h-[72px] rounded w-[320px] bg-[#ffffff05] text-[18px]"
                >
                  <img src={SolanaLogo} alt="" />
                </button>
                {/* <button disabled={isLoading} onClick={() => { handleButtonClick("Ethereum"); setChooseChain("Solana") }} className="flex justify-start px-[28px] items-center h-[72px] rounded w-[320px] bg-[#ffffff05] text-[18px] mt-[17px]">
                                <img src={EthLogo} alt="" />
                            </button> */}
                <button
                  onClick={() => {
                    handleButtonClick("Starknet");
                    setChooseChain("Starknet");
                  }}
                  className="flex justify-start px-[28px] items-center h-[72px] rounded w-[320px] bg-[#ffffff05] text-[18px] mt-[17px]"
                >
                  <img src={starknetLogo} alt="" />
                </button>
                {/* <button
                  onClick={() => {
                    handleButtonClick("Ethereum");
                    setChooseChain("Ethereum");
                  }}
                  className="flex justify-start px-[28px] items-center h-[72px] rounded w-[320px] bg-[#ffffff05] text-[18px] mt-[17px]"
                >
                  <img src={polygonLogo} alt="" />
                </button> */}
              </div>
              <div className="my-[17px]">
                <img src={lineBreak} />
              </div>
              <div>
                <button
                  onClick={() => {
                    handleButtonClick("Solana-Social");
                    setChooseChain("Solana-Social");
                  }}
                  className="h-[72px] w-[320px] rounded font-normal bg-[#ffffff05] text-[18px]"
                >
                  LOGIN WITH SOCIAL
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserSignIn;
