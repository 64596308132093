import React from 'react'
import { DOCUMENTATION_LINK, SUPPORT_LINK } from '../../constants'
import EndpointAPI from '../EndpointAPI/EndpointAPI'
import ProjectDetails from '../ProjectDetails/ProjectDetails'
import UsageMetrics from '../UsageMetrics/UsageMetrics'

import './Whitelisted.css'

type WhitelistedProps = {
  name: string,
  programId: string,
  endpointApi: string,
  adminSecret: string,
  projectMetrics: Record<string, any>
}

function Whitelisted(props: WhitelistedProps) {
  const {name, programId, endpointApi, adminSecret, projectMetrics= {}} = props;

  return (
    <div className='whitelisted__container'>
      {/* <ProjectDetails name={name} programId={programId} /> */}
      <EndpointAPI endpointApi={endpointApi} adminSecret={adminSecret} documentationLink={DOCUMENTATION_LINK} supportLink={SUPPORT_LINK} />
      <UsageMetrics projectMetrics={projectMetrics} />
    </div>
  )
}

export default Whitelisted