function ProjectDetails(props: any) {
  const { formValues, error, validateInput, setFormValue } = props;

  const handleChange = (e: any) => {
    const { name, value } = e.currentTarget;
    validateInput(name, value);
    setFormValue({ ...formValues, [name]: value });
  };

  return (
    <>
      <div className="mb-5">
        <label
          className="block text-left text-[#CAC8C8] text-sm mb-[4px]"
          htmlFor="username"
        >
          Project Name
        </label>
        <input
          name="programName"
          value={formValues.programName}
          onChange={handleChange}
          className="bg-[#ffffff1a] shadow appearance-none rounded-[5px] w-full h-[36px] px-[11px] text-white leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="example"
          required
        />
        <span className="absolute z-2 text-xs text-[#FF5B5B] flex justify-start items-center pt-[2px]">
          {error.programName === "" ? "" : error.programName}
        </span>
      </div>
      <div className="mb-5">
        <label
          className="block text-left text-[#CAC8C8] text-sm mb-2"
          htmlFor="username"
        >
          Website
        </label>
        <input
          name="website"
          value={formValues.website}
          required
          onChange={handleChange}
          className="bg-[#ffffff1a] shadow appearance-none rounded-[5px] w-full h-[36px] px-[11px] text-white leading-tight focus:outline-none focus:shadow-outline"
          type="url"
          placeholder="www.example.com"
        />
        <span className="absolute z-2 text-xs text-[#FF5B5B] flex justify-start items-center pt-[2px]">
          {error.website === "" ? "" : error.website}
        </span>
      </div>
      <div className="mb-5">
        <label className="block text-left text-[#CAC8C8] text-sm mb-2">
          Email ID
        </label>
        <input
          name="email"
          value={formValues.email}
          required
          onChange={handleChange}
          className="bg-[#ffffff1a] shadow appearance-none rounded-[5px] w-full h-[36px] px-[11px] text-white leading-tight focus:outline-none focus:shadow-outline"
          type="email"
          placeholder="hello@example.com"
        />
        <span className="absolute z-2 text-xs text-[#FF5B5B] flex justify-start items-center pt-[2px]">
          {error.email === "" ? "" : error.email}
        </span>
      </div>
      <div className="mb-5">
        <label
          className="block text-left text-[#CAC8C8] text-sm mb-2"
          htmlFor="username"
        >
          Twitter ID
        </label>
        <input
          name="twitter"
          value={formValues.twitter}
          required
          onChange={handleChange}
          className="bg-[#ffffff1a] shadow appearance-none rounded-[5px] w-full h-[36px] px-[11px] text-white leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="@example_Dapp"
        />
        <span className="absolute z-2 text-xs text-[#FF5B5B] flex justify-start items-center pt-[2px]">
          {error.twitter === "" ? "" : error.twitter}
        </span>
      </div>
      <div className="">
        <label
          className="block text-left text-[#CAC8C8] text-sm mb-2"
          htmlFor="username"
        >
          Discord ID
        </label>
        <input
          name="discord"
          value={formValues.discord}
          required
          onChange={handleChange}
          className="bg-[#ffffff1a] shadow appearance-none rounded-[5px] w-full h-[36px] px-[11px] text-white leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="username#1234"
        />
        <span className="absolute z-2 text-xs text-[#FF5B5B] flex justify-start items-center pt-[2px]">
          {error.discord === "" ? "" : error.discord}
        </span>
      </div>
    </>
  );
}

export default ProjectDetails;
