import { useEffect, useState } from "react"
import Copy from "./assets/copy.png"
import Copied from "./assets/copied.png"
import { getUserDetails, createApiToken } from "../../apiHelper";

function Profile(props: any) {
    const { walletAddress, email } = props;

    let UserID = walletAddress;

    const [ApiKey, setApiKey] = useState<any>("");
    const [isCopied, setIsCopied] = useState<boolean>(false)
    const [apiKeyStatus, setApiKeyStatus] = useState<string>("")

    const handleGenerate = async () => {
        const res = await createApiToken(UserID)
        setApiKey(res.data.apiToken)
        setApiKeyStatus("")
    }

    async function copyTextToClipboard(value: any) {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(value);
        } else {
            return document.execCommand("copy", true, value);
        }
    }

    useEffect(() => {
        if (email && email.length > 3) {
            UserID = email
        }

        const userDetails = async () => {
            const data = await getUserDetails(UserID);
            if (data.user === null || data.user.apiToken === undefined) {
                setApiKeyStatus("Click on Generate")
            } else {
                setApiKey(data.user.apiToken)
                setApiKeyStatus("")
            }
        }

        if (walletAddress === "" || email === "") {
            window.location.replace("/");
        }
        userDetails()
    }, [])

    return (
        <>
            <div className="mt-[81px] h-[calc(100%-81px)] h-[inherit] flex justify-center items-center">
                <div className="mt-[40px] py-[20px]">
                    <p className="text-left text-[#cac8c8]">You're API Key</p>
                    <div className="flex justify-between items-center w-[500px] mb-[10px]">
                        <input
                            type="password"
                            value={ApiKey}
                            className="bg-[#ffffff1c] text-[#efefefcc] px-[18px] py-[12px] rounded-[4px] w-full h-[50px] mr-[16px]"
                            placeholder={apiKeyStatus}
                            disabled
                        />
                        {isCopied ?
                            <img width="5%" src={Copied} />
                            :
                            <img onClick={() => {
                                copyTextToClipboard(ApiKey)
                                setIsCopied(true);
                                setTimeout(() => { setIsCopied(false) }, 2000)
                            }
                            } className="cursor-pointer" src={Copy} />
                        }
                    </div>
                    {apiKeyStatus === "Click on Generate" ?
                        <button onClick={handleGenerate} className="h-[36px] w-[128px] flex justify-center items-center bg-gradient-to-r from-[#F55151] to-[#FFB800] rounded border text-black border-[#333232] text-[14px] font-normal colored">Generate</button>
                        :
                        ""
                    }
                </div>
            </div>
        </>
    )
}

export default Profile