import React, { useState, useEffect } from "react";
import logo from "../../../assets/ConciseLogo.svg";
import { getAuth } from "@firebase/auth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, redirect } from "react-router-dom";
import { SUPPORT_LINK, DOCUMENTATION_LINK, BLOG_LINK, CASESTUDIES_LINK } from "../../../constants";
// import NavItems from "../NavItems/NavItems";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";

import { initilize, logout } from "../../ChooseChain/loginUtilities";

function NavBar(props: any) {
  // const { setStatus } = props;
  const { walletAddress, setWalletAddress, chooseChain, wantToLogin, setWantToLogin, setTab, tab, email, googleAuthResp, setStatus } = props;
  const [isCopied, setIsCopied] = useState(false)
  // const [walletAddress, setWalletAddress] = useState<any>(null)
  const [toggleLogoutHover, setToggleLogoutHover] = useState(false)
  // const [email, setEmail] = useState<any>(null)
  const [chain, setChain] = useState("")
  const [toggleSelect, setToggleSelect] = useState(false);
  // const [tab, setTab] = useState("playground");
  const wallet = useWallet();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);




  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentUser = props.currentUser || {};
  const { setCurrentUser } = props;

  const signout = async () => {
    handleClose();
    const auth = getAuth();

    const resp = await auth.signOut();
    console.log("logout res", resp);
    setTimeout(() => {
      setCurrentUser(null);
      redirect("/");
      console.log(auth.currentUser, "current login user");
    }, 1000);
  };

  const web3AuthLogOut = async (chain: string) => {
    try {
      await logout(chain)
      setWalletAddress("")
      setWantToLogin(false)
    } catch (error) {
      console.log("Something went wrong with Logout", error)
    }
  }
  async function copyTextToClipboard(value: any) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(value);
    } else {
      return document.execCommand("copy", true, value);
    }
  }

  return (
    <>
      <div className="flex flex-wrap place-items-top z-50 h-screen max-h-[62px] border-gray-700 w-full fixed" style={{ fontFamily: "poppins" }}>
        {/* navbar */}
        <nav className="flex justify-between bg-[#000000] border-b-[#333232] border-b text-white w-screen">
          <div className="px-6 flex w-full items-center justify-between">
            <div className="flex justify-center items-center">
              <a className="text-3xl font-bold font-heading mr-[47px]" href="/">
                <img className="h-[32px]" src={logo} alt="logo" />
              </a>
            </div>
            <div className="flex ">
              <NavLink to="/playground">
                <div>
                  <div onClick={() => { setTab("playground"); setToggleSelect(false); setWantToLogin(false) }} className="py-[28px] text-[14px] px-[18.5px] font-normal cursor-pointer hover:bg-[#ffffff1a] text-[#efefefcc]">
                    Playground
                  </div>
                  {/* {tab === "/playground" && (
                    <div className="absolute top-[78px] h-[1px] w-[127px] bg-white"></div>
                  )} */}
                </div>
              </NavLink>
              {/* <NavLink to="projects">
                <div onClick={() => { setTab("projects"); setToggleSelect(false); }} className="py-[28px] px-[18.5px] cursor-pointer hover:bg-[#ffffff1a]">
                  Projects
                </div>
                {tab === "projects" && (
                  <div className="absolute top-[78px] h-[1px] w-[100px] bg-white"></div>
                )}
              </NavLink> */}
              <div className="mr-[130px]" onMouseLeave={() => { setToggleSelect(false) }}>
                <button
                  onClick={() => {
                    setToggleSelect(!toggleSelect);
                    // setTab("/resources");
                  }}
                  className="flex justify-center items-center absolute py-[28px] px-[18.5px] text-[14px] font-normal cursor-pointer hover:bg-[#ffffff1a] text-[#efefefcc]"
                >
                  Resources <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
                {/* {tab === "/resources" && (
                  <div className="absolute top-[78px] h-[1px] w-[139px] bg-white"></div>
                )} */}
                <div
                  className={`absolute mt-[78px] ${toggleSelect === false ? "hidden" : ""
                    }`}
                >
                  {/* <ul className="bg-[#ffffff1c] rounded text-left"> */}
                  <ul className="bg-gray-700 rounded text-left">
                    <li>
                      <a href="https://api-docs.conciselabs.io/docs/introduction" target="_blank">
                        <button className="text-[#efefefcc] hover:text-white px-[18px] py-[12px] text-[16px] border-b border-b-[#333232]">
                          Documentation
                        </button>
                      </a>
                    </li>
                    <li>
                      <a href={CASESTUDIES_LINK} target="_blank">
                        <button className="text-[#efefefcc] hover:text-white px-[18px] py-[12px] text-[16px] border-b border-b-[#333232]">
                          Case Studies
                        </button>
                      </a>
                    </li>
                    <li>
                      <a href={BLOG_LINK} target="_blank">
                        <button className="text-[#efefefcc] hover:text-white px-[18px] py-[12px] text-[16px]">
                          Blogs
                        </button>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <a
                  href={SUPPORT_LINK}
                  target="_blank"
                >
                  <div onClick={() => { initilize(chain) }} className="py-[28px] text-[14px] px-[18.5px] font-normal cursor-pointer hover:bg-[#ffffff1a] text-[#efefefcc]">
                    Raise Ticket
                  </div>
                </a>

              </div>
              <div className="flex justify-center items-center">
                {wantToLogin === false && walletAddress === "" ?
                  <>
                    <NavLink to="/api"><button onClick={() => { setWantToLogin(true); setStatus("Login") }} className="h-[36px] mr-[10px] w-[67px] flex justify-center items-center rounded border text-[#efefefcc] border-[#333232] text-[14px] font-normal hover:text-white">Sign In</button></NavLink>
                    <NavLink to="/api"><button onClick={() => { setWantToLogin(true); setStatus("Get Started") }} className="h-[36px] w-[128px] flex justify-center items-center bg-gradient-to-r from-[#F55151] to-[#FFB800] rounded border text-black border-[#333232] text-[14px] font-normal colored">Get Started</button></NavLink>
                  </>
                  :
                  ""
                }
                {walletAddress !== "" ?
                  <>
                    <div className="relative ml-[10px]" onMouseEnter={() => { setToggleLogoutHover(!toggleLogoutHover) }} onMouseLeave={() => { setToggleLogoutHover(!toggleLogoutHover) }}>
                      {Object.keys(googleAuthResp.userInfo).length === 0 ?
                        <button onClick={() => {
                          copyTextToClipboard(walletAddress); setIsCopied(true); setTimeout(() => {
                            setIsCopied(false)
                          }, 500);
                        }} className="h-[36px] w-[105px] flex justify-center items-center rounded border text-[#efefefcc] border-[#333232] text-[14px] font-normal hover:text-white">{isCopied ? "Copied" : `${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`}</button>
                        :
                        <button onClick={() => {
                          copyTextToClipboard(googleAuthResp.userInfo.email); setIsCopied(true); setTimeout(() => {
                            setIsCopied(false)
                          }, 2000);
                        }} className="h-[36px] w-[120px] flex justify-center items-center rounded border text-[#efefefcc] border-[#333232] text-[14px] font-normal hover:text-white">{isCopied ? "Copied" : googleAuthResp.userInfo.name}</button>
                      }
                      <div className={`absolute ${toggleLogoutHover === false ? "hidden" : ""}`}>
                        <ul className="bg-[#ffffff1c] rounded">
                          <li>
                            <button onClick={() => { web3AuthLogOut(chooseChain); setTimeout(() => { window.location.replace("/api") }, 1000) }} className="w-[105px] h-[36px] text-[#efefefcc] hover:text-white text-[14px]">
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="flex justify-center items-center cursor-pointer ml-[5px]">
                      <NavLink to="profile" onClick={() => { setTab("profile") }}>
                        <button className="h-[36px] w-[128px] flex justify-center items-center bg-gradient-to-r from-[#F55151] to-[#FFB800] rounded border text-black border-[#333232] text-[14px] font-normal colored">Get API Key</button>
                      </NavLink>
                    </div> */}
                  </>
                  :
                  ""
                }
              </div>
            </div>
          </div>
        </nav>
      </div >
      {/* <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>
          <NavLink to="/account">My account</NavLink>
        </MenuItem>
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu> */}
    </>
  );
}

export default NavBar;
