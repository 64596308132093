import './EndpointAPI.css'
import CopySVG from '../../assets/Copy.svg'
import BookSVG from '../../assets/Book.svg'
import ChatSVG from '../../assets/Chat.svg'
import DotsSVG from '../../assets/Dots.svg'
import VerticalLineSVG from '../../assets/SecretVerticalLine.svg'

import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useState } from 'react';

type EndpointProps = {
    endpointApi: string,
    adminSecret: string,
    documentationLink: string,
    supportLink: string,
}

const handleOpen = (link: string | URL | undefined) => {
    window.open(link, '_blank');
}

function EndpointAPI(props: EndpointProps) {
  const [isAPICopied, setIsAPICopied] = useState(false)
  const [isHasuraTextCopied, setIsHasuraTextAPICopied] = useState(false)
  const [isHasuraSecretCopied, setIsHasuraSecretCopied] = useState(false)

  return (
    <div className='endpoint__container flex flex-col sm:flex-row'>
        <div className='endpoint__left'>
            <div className='endpoint__herotext'>API Endpoint</div>
            <div className="endpoint__subtext">Use this API endpoint  to create your own queries. Try it out on the playground or check documentation</div>
            <div className="api__link_secret">
                <div className="endpoint__api_pg">
                    <div className="endpoint__api_links">
                        
                        <div className="endpoint__apilink">
                            <span className="enpoint_linkapi">{props.endpointApi}</span>
                            {!isAPICopied && 
                                <img 
                                    src={CopySVG} 
                                    alt="copy" 
                                    className='endpoint__copy' 
                                    onClick={() => {
                                        navigator.clipboard.writeText(props.endpointApi);
                                        setIsAPICopied(true)
                                        setTimeout(() => {
                                            setIsAPICopied(false);
                                        }, 1500)
                                    }} 
                                />
                            }
                            {isAPICopied && <DoneRoundedIcon className='endpoint__tick endpoint__copy' />}
                        </div>
                    </div>
                    <a 
                        href={`https://cloud.hasura.io/public/graphiql?endpoint=${props.endpointApi}`} 
                        target={'_blank'} 
                        rel='noreferrer'
                    >
                        <button className="endpoint__pgbutton">
                            Playground
                        </button>
                    </a>
                </div>

                {props.adminSecret &&
                    <div className="flex flex-col sm:flex-row mt-20 sm:mt-0">
                        <div className="secret__keypart">
                            <div className="secret__keytext">Key</div>
                            <div className="secret__keyvalue">
                                x-hasura-admin-secret
                                {!isHasuraTextCopied && 
                                    <img 
                                        src={CopySVG} 
                                        alt="copy"
                                        className='secret__copy'
                                        onClick={() => {
                                            navigator.clipboard.writeText('x-hasura-admin-secret');
                                            setIsHasuraTextAPICopied(true)
                                            setTimeout(() => {
                                                setIsHasuraTextAPICopied(false);
                                            }, 1500)
                                        }} 
                                    />
                                }
                                {isHasuraTextCopied && <DoneRoundedIcon className='secret__tick' />}
                            </div>
                        </div>
                        <img src={VerticalLineSVG} alt="line" className='mx-[18px] hidden sm:block'/>
                        <div className="secret__valuepart mt-4 sm:mt-0">
                        <div className="secret__keytext">Value</div>
                            <div className="secret__keyvalue">
                                <img src={DotsSVG} alt="*********" />
                                {!isHasuraSecretCopied && 
                                    <img 
                                        src={CopySVG} 
                                        alt="copy"
                                        className='secret__copy'
                                        onClick={() => {
                                            navigator.clipboard.writeText(props.adminSecret);
                                            setIsHasuraSecretCopied(true)
                                            setTimeout(() => {
                                                setIsHasuraSecretCopied(false);
                                            }, 1500)
                                        }} 
                                    />
                                }
                                {isHasuraSecretCopied && <DoneRoundedIcon className='secret__tick' />}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div className="flex flex-col items-start sm:items-center grow right__container__links">
            <div className="endright__container flex flex-col justify-start sm:justify-center border-l-0 sm:border-l">
                <div className="endright__documentation" onClick={() => {handleOpen(props.documentationLink)}}>
                    <img src={BookSVG} alt="documentation" className='endright__svg' />
                    Documentation
                </div>
                <div className="endright__raiseticket" onClick={() => handleOpen(props.supportLink)}>
                    <img src={ChatSVG} alt="raise ticket" className='endright__svg' />
                    Raise Support Ticket
                </div>
            </div>
        </div>
    </div>
  )
}

export default EndpointAPI