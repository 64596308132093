const getTruncatedPubkey = (pubkey: string) => {
    const strlen = pubkey.length;
  
    return pubkey.substring(0, 4) + '...' + pubkey.substring(strlen - 4)
}

const getTruncatedSecret = (secret: string) => {
    return secret.substring(0, 28) + '...'
}

export {
    getTruncatedPubkey,
    getTruncatedSecret
}