import './UsageMetrics.css'
import MetricsSVG from '../../assets/Metrics.svg'
import MetricsData from '../MetricsData/MetricsData'
import { SUPPORT_LINK } from '../../constants'
import moment from 'moment'

type UsageProps = {
    projectMetrics: Record<string, any>,
}

function UsageMetrics(props: UsageProps) {
  return (
    <div className='metrics__container relative flex flex-col sm:flex-row'>
        <div className="metrics__left">
            <div className="metrics__herotext">Usage Metrics</div>
            <div className="metrics__plan">
                <span className="metrics__plantext">Current plan: Free (60 requests per minute | 1 GB data pass-through)</span>
                <a href={SUPPORT_LINK} className="metrics__planupgrade" target='_blank' rel='noreferrer'>Contact Us</a>
            </div>
            <MetricsData lastOneDay={props.projectMetrics.last_one_day} lastOneHr={props.projectMetrics.last_one_hr} errors={-1} />
            <div className="metrics__lastmonth">
                *Last 1 month stats {props.projectMetrics && <span>|    Last updated on {moment.unix(props.projectMetrics.last_updated_on || Date.now() / 1000).format('DD MMM YYYY: LT')}</span>}
            </div>
        </div> 
        <div className="metrics__right mr-2 sm:mr-10 absolute sm:relative bottom-[10px] sm:bottom-0 right-[10px] sm:right-0 w-1/2 sm:w-auto">
            <img src={MetricsSVG} alt="metrics" />
        </div>
    </div>
  )
}

export default UsageMetrics