import React, { useState } from "react";
import DisplayNft from "./DisplayNft/DisplayNft";
import { Prism } from "@mantine/prism";
import Error from "./Error/Error";
import {
  getNFTMetaDataEth,
  getNFTMetaDataSolana,
} from "../../../../../apiHelper";
import CircularProgress from "@mui/material/CircularProgress";
import SubmitButtonSvg from "../../../assets/submitButton.png";
import EthLogo from "../../../assets/EthLogo.png";
import SolanaLogo from "../../../assets/SolanaLogo.png";

function NftMetadata(props: any) {
  const { currentUser, renderConfetti } = props;
  const [isLoading, setLoading] = useState(false);
  const [displayChain, setDisplayChain] = useState("Solana");
  const [chain, setChain] = useState("Solana");
  const [visibility, setVisibility] = useState<boolean>(true);
  const [contractAddress, setContractAddress] = useState(
    "6Sz9mKqFsY4KfxzVBaboDk1tzyoK3BPrxSKQLMEoASon"
  );
  const [displayContractAddress, setdisplayContractAddress] = useState("");
  const [nftId, setNftId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [owner, setOwner] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [metadata, setMetadata] = useState<any>({});
  const [error, setError] = useState<any>(false);
  // const [ethereumMetadata, setEthereumMetadata] = useState({})

  const handleChainChange = (e: any) => {
    const { value } = e.currentTarget;
    if (value === "Solana") {
      setMetadata({})
      setDisplayChain("Solana");
      setContractAddress("6Sz9mKqFsY4KfxzVBaboDk1tzyoK3BPrxSKQLMEoASon");
    }
    if (value === "Ethereum") {
      setMetadata({})
      setDisplayChain("Ethereum")
      setContractAddress("0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D");
      setNftId("2345");
    }
    setChain(value);
  };
  const handleContractChange = (e: any) => {
    const { value } = e.currentTarget;
    setContractAddress(value);
  };
  const handleNftIdChange = (e: any) => {
    const { value } = e.currentTarget;
    setNftId(value);
  };

  const handleImageUrl = (url: any) => {
    if (url && url.startsWith("ipfs")) {
      url = `https://gateway.pinata.cloud/ipfs/${url.replace("ipfs://", "")}`;
    }
    setImageUrl(url.replace(`/${nftId}`, ""));
    return url;
  };

  const getSolanaMetadata = async () => {
    setLoading(true);

    const res = await getNFTMetaDataSolana(
      contractAddress,
      currentUser.apiToken
    );
    renderConfetti();
    setdisplayContractAddress(contractAddress);
    setDisplayChain("Solana");
    setMetadata(res);
    console.log(metadata);
    setImageUrl(handleImageUrl(res["metadata"]["image"]));
    // setOwner(output['metadata']['owner'])
    setName(res["metadata"]["name"]);
    setSymbol(res["metadata"]["symbol"]);
    setLoading(false);
  };

  const getEthereumMetadata = async () => {
    setLoading(true);
    const res = await getNFTMetaDataEth(
      contractAddress,
      parseInt(nftId),
      currentUser.apiToken
    );
    renderConfetti();
    setdisplayContractAddress(contractAddress);
    setDisplayChain("Ethereum");
    setMetadata(res);
    setImageUrl(handleImageUrl(res["metadata"]["image"]));
    setOwner(res["owner"]);
    setName(res["name"]);
    setSymbol(res["symbol"]);
    setLoading(false);
  };

  const handleError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 7000);
  };

  const handleSubmit = async () => {
    try {
      if (chain === "Solana") {
        await getSolanaMetadata();
      } else if (chain === "Ethereum") {
        await getEthereumMetadata();
      } else {
        handleError();
      }
    } catch (error: any) {
      setError(error);
      setLoading(false);
      handleError();
    }
  };

  async function copyTextToClipboard(value: any) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(value);
    } else {
      return document.execCommand("copy", true, value);
    }
  }

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className="max-w-[941px] pb-[20px]">
      <div className="bg-[#0000006e] w-full rounded">
        <div className="text-[20px] font-[500] px-[20px] text-left pt-[20px] text-[#C8C8C8]">
          Fetch NFT Metadata
        </div>
        <div className="flex px-[20px] mt-[19px] pb-[38px]" onMouseLeave={()=>{setVisibility(true)}}>
        <button data-dropdown-placement="bottom"  className="text-white bg-[#ffffff1c]  focus:outline-none   font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-[#ffffff1c] hover:bg-[#ffffff1c] focus:ring-blue-800" type="button"  onClick={()=>{setVisibility(!visibility)}}  >{displayChain} <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
          <div id="dropdownUsers"  className={` absolute mt-[55px] mr-1   ${visibility === true ? "hidden" : ""}  bg-[#ffffff1c] rounded-lg shadow w-30 bg-[#ffffff1c]`}>
              <ul className="h-17 overflow-y-auto text-gray-700   text-gray-200"   >
                <li>
                  <button  className="flex  w-full rounded px-2  hover:bg-[#ffffff1a] hover:text-white items-center px-4 py-2 " value="Solana" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={SolanaLogo} alt="Solana Image" />
                    Solana
                  </button>
                </li>
                <li>
                  <button  className="flex w-full rounded px-2 items-center px-4 py-2 hover:bg-[#ffffff1a] hover:text-white" value="Ethereum" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={EthLogo} alt="Ethereum Image" />
                    Ethereum
                  </button>
                </li>
              </ul>
            </div>
          {chain === "Ethereum" ? (
            <>
              <input
                onChange={handleContractChange}
                value={contractAddress}
                onKeyDown={handleKeypress}
                type="text"
                className="bg-[#ffffff1c] px-[18px] text-[#efefefcc] text-[16px] py-[12px] rounded-[4px] w-full h-[50px] ml-[17px]"
                placeholder="Contract Address"
              />
              <input
                onChange={handleNftIdChange}
                value={nftId}
                onKeyDown={handleKeypress}
                className="bg-[#ffffff1c] px-[18px] text-[#efefefcc] text-[16px] py-[12px] rounded-[4px] w-[125px] h-[50px] mx-[17px]"
                type="number"
                placeholder="NFT ID"
              />
            </>
          ) : (
            <input
              onChange={handleContractChange}
              value={contractAddress}
              onKeyDown={handleKeypress}
              type="text"
              className="bg-[#ffffff1c] text-[#efefefcc] text-[16px] px-[18px] py-[12px] rounded-[4px] w-full h-[50px] mx-[17px]"
              placeholder="Mint Address"
            />
          )}
          <button
            disabled={chain === "" || isLoading ? true : false}
            onClick={handleSubmit}
            className="colored h-[50px] w-[85px] flex justify-center items-center rounded-[4px] bg-gradient-to-r from-[#F55151] to-[#FFB800]"
          >
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <img src={SubmitButtonSvg} alt="" />
            )}
          </button>
        </div>
      </div>
      {error === true ? <Error /> : ""}
      {Object.keys(metadata).length > 1 && name && symbol && (
        <DisplayNft
          contractAddress={displayContractAddress}
          owner={owner}
          name={name}
          symbol={symbol}
          imageUrl={imageUrl}
          chain={displayChain}
          copyTextToClipboard={copyTextToClipboard}
          metadata={metadata}
        />
      )}
      {metadata.metadata === undefined ? (
        ""
      ) : (
        <>
          <div className="my-[20px] text-left text-[16px]">
            Attributes
          </div>
          {chain === "Solana" ?
            <div className="grid grid-cols-3 gap-[20px] mt-[20px]">
              {metadata['metadata']['attributes'].slice(1, 7).map((key: any) =>
                <div className="bg-[#0000006e] py-[28px] rounded" key={key}>
                  <p className="text-[20px] text-[#C8C8C8] font-[500]">{key.value}</p>
                  <p className="text-[16px] text-[#575757] font-[400]">{key.trait_type}</p>
                </div>
              )}
            </div>
            :
            <div className="grid grid-cols-3 gap-[20px] mt-[20px]">
              {metadata['metadata']['attributes'].map((key: any) =>
                <div className="bg-[#0000006e] py-[28px]" key={key}>
                  <p className="text-[20px] text-[#C8C8C8] font-[500]">{key.value}</p>
                  <p className="text-[16px] text-[#575757] font-[400]">{key.trait_type}</p>
                </div>
              )}
            </div>
          }
          <div className="my-[20px] text-left text-[16px]">
            Metadata
          </div>
          <div className="text-left w-full">
            <Prism colorScheme="dark" withLineNumbers language="tsx">
              {JSON.stringify(metadata, null, 4)}
            </Prism>
          </div>
        </>
      )}
    </div>
  );
}

export default NftMetadata;
