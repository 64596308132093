import { getSolPortFolio } from "../../../../../apiHelper";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
// import priceSVG from "../../../assets/price.png";
import SubmitButtonSvg from "../../../assets/submitButton.png";
import Error from "./Error/Error";
import EthLogo from "../../../assets/EthLogo.png";
import SolanaLogo from "../../../assets/SolanaLogo.png";

function TotalBalance(props: any) {
  const { currentUser, renderConfetti } = props;
  const [visibility, setVisibility] = useState<boolean>(true)
  const [isLoading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(
    "FubSWvPViUgm9tbQtHDQAxWe4YgExSo2AdKGnxZV9Dkp"
  );

  const [portfolio, setPortfolio] = useState<any>({});
  const [error, setError] = useState<any>("");
  const [chain, setChain] = useState("Solana");

  const handleChange = (e: any) => {
    const { value } = e.currentTarget;
    setWallet(value);
  };

  async function copyTextToClipboard() {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(wallet);
    } else {
      return document.execCommand("copy", true, wallet);
    }
  }

  const handleError = (message: string) => {
    setError(message);
    setTimeout(() => {
      setError(false);
    }, 7000);
  };

  const handleChainChange = (e: any) => {
    const { value } = e.currentTarget;
    setChain(value);
    console.log(chain);
  };

  const handleSubmit = async () => {
    try {
      if (wallet !== "" && wallet.length > 30 && chain === "Solana") {
        setLoading(true);
        const resp = await getSolPortFolio(currentUser.apiToken, wallet);
        renderConfetti();
        console.log(resp);
        resp.data && setPortfolio(resp.data);
        setLoading(false);
        if (!resp.data) {
          handleError("Something went wrong! Please try again");
        }
      } else {
        console.log("Please enter wallet address");
        handleError("Something went wrong! Please try again");
      }
    } catch (error: any) {
      handleError("Something went wrong! Please try again");
      setLoading(false);
    }
  };
  const handleKeypress = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className="pb-[20px]">
      <div className="bg-[#0000006e] w-full rounded">
        <div className="text-[20px] font-[500] px-[20px] text-left pt-[20px] text-[#C8C8C8]">
          Check Wallet Balance
        </div>
        <div className="flex px-[20px] mt-[19px] pb-[38px]" onMouseLeave={()=>{setVisibility(true)}}>
        <button data-dropdown-placement="bottom"  className="text-white bg-[#ffffff1c]  focus:outline-none   font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-[#ffffff1c] hover:bg-[#ffffff1c] focus:ring-blue-800" type="button"  onClick={()=>{setVisibility(!visibility)}}  >Solana <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
          <div id="dropdownUsers"  className={` absolute mt-[55px] mr-1   ${visibility === true ? "hidden" : ""}  bg-[#ffffff1c] rounded-lg shadow w-30 bg-[#ffffff1c]`}>
              <ul className="h-17 overflow-y-auto text-gray-700   text-gray-200"   >
                <li>
                  <button  className="flex w-full rounded px-2 items-center px-4 py-2 hover:bg-[#ffffff1a] hover:text-white" value="Solana" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={SolanaLogo} alt="Solana Image" />
                    Solana
                  </button>
                </li>
                <li>
                  <button  className="flex cursor-not-allowed text-neutral-400 w-full rounded px-2  items-center px-4 py-2 " value="Ethereum" onClick={handleChainChange}>
                    <img className="w-6 h-6 mr-2 rounded-full" src={EthLogo} alt="Ethereum Image" />
                    Ethereum
                  </button>
                </li>
              </ul>
            </div>
          <input
            onChange={handleChange}
            value={wallet}
            onKeyDown={handleKeypress}
            type="text"
            className="bg-[#ffffff1c] text-[#efefefcc] px-[18px] text-[16px] py-[12px] rounded-[4px] w-full h-[50px] mx-[16px]"
            placeholder="Wallet Address"
          />
          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className="colored h-[50px] w-[85px] flex justify-center items-center rounded-[4px] bg-gradient-to-r from-[#F55151] to-[#FFB800]"
          >
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <img src={SubmitButtonSvg} alt="" />
            )}
          </button>
        </div>
      </div>
      {error ? <Error error={error} /> : ""}
      {portfolio.tokens === undefined ? (
        ""
      ) : (
        <>
          <div className="flex justify-between mt-[20px]">
            <div className="bg-[#0000006e] w-[299px] h-[95px] flex flex-col items-center justify-center rounded">
              <p className="px-[27px] pt-[18px] font-[500] text-[20px]  pb-[4px]">
                $
                {portfolio.portfolioValue === undefined
                  ? ""
                  : portfolio["portfolioValue"].toFixed(4)}
              </p>
              <p className="px-[27px] pb-[18px] font-[400] pb-[18px] text-[16px] text-[#575757]">
                Total Funds
              </p>
            </div>
            <div className="bg-[#0000006e] w-[299px] h-[95px] flex flex-col items-center justify-center mx-[20px] rounded">
              <p className="px-[27px] pt-[18px] text-[20px] pb-[4px] font-[500]">
                {portfolio.tokens === undefined
                  ? ""
                  : portfolio["tokens"].length}
              </p>
              <p className="px-[27px] pb-[18px] pb-[18px] font-[400] text-[16px] text-[#575757]">
                Assets
              </p>
            </div>
            <div className="bg-[#0000006e] w-[299px] h-[95px] flex flex-col items-center justify-center rounded">
              <p
                className="px-[27px] pt-[18px] text-[20px]  pb-[4px] font-[500]"
                onClick={copyTextToClipboard}
              >{`${wallet.slice(0, 4)}...${wallet.slice(-4)}`}</p>
              <p className="px-[27px] pb-[18px] pb-[18px] text-[16px] font-[400] text-[#575757]">
                Wallet
              </p>
            </div>
          </div>
          <div className="relative overflow-x-auto mt-[20px] rounded">
            <table className="portfolio-table bg-[#0000006e] w-full text-sm text-left text-white">
              <thead className="text-[16px] font-normal text-[#575757] bg-[#0000006e]">
                <tr>
                  <td scope="col" className="px-6 py-3">
                    Asset
                  </td>
                  <td scope="col" className="px-6 py-3">
                    Price
                  </td>
                  <td scope="col" className="px-6 py-3">
                    Amount
                  </td>
                  <td scope="col" className="px-6 py-3">
                    Value
                  </td>
                  {/* <td scope="col" className="px-6 py-3">
                    %
                  </td> */}
                </tr>
              </thead>
              <tbody className="text-[#C8C8C8]">
                {portfolio.tokens.map((token: any, index: number) => {
                  const allocation: any = Number(
                    ((token.balance * token.price) / portfolio.portfolioValue) *
                    100
                  ).toFixed(8);
                  return (
                    <tr
                      className={`bg-[#0000006e] ${index === portfolio.tokens.length - 1 ? "" : "border-b"
                        } dark:border-gray-700`}
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 flex items-center font-normal text-[16px] text-[#efefefcc] whitespace-nowrap"
                      >
                        <img
                          width="5%"
                          className="mr-[10px]"
                          src={token.tokenMetaData.logoURI}
                          alt=""
                        />
                        {token.tokenMetaData.name ? (
                          `${token.tokenMetaData.name} (${token.tokenMetaData.symbol})`
                        ) : (
                          <Tooltip title={token.mintAddress}>
                            <div className="cursor-pointer">Unknown</div>
                          </Tooltip>
                        )}
                      </td>
                      <td className="px-6 py-4 ">
                        <div className="flex">
                          {/* <img className="mr-[7.5px]" src={priceSVG} alt="" /> */}
                          <p className="font-normal text-[16px] text-[#efefefcc]">
                            ${Number(token.price).toFixed(6)}
                          </p>
                        </div>
                      </td>

                      {/* <td className="px-6 py-4">
                        {`${Number(token.balance)} ${token.tokenMetaData.symbol
                          ? token.tokenMetaData.symbol
                          : "Unknown"
                          }`}
                      </td> */}
                      {/* <td className="px-6 py-4">
                        {}
                      </td> */}

                      {token.tokenMetaData.symbol ? (
                        <td className="px-6 py-4">
                          <div className="flex">
                            <p className="mr-[5px] font-normal text-[16px] text-[#efefefcc]">
                              {token.balance}
                            </p>
                            <p className="font-normal text-[16px] text-[#efefefcc]">
                              {token.tokenMetaData.symbol}
                            </p>
                          </div>
                          {/* {token.balance} {token.tokenMetaData.symbol} */}
                        </td>
                      ) : (
                        <Tooltip title={token.mintAddress}>
                          <td className="px-6 py-4 cursor-pointer font-normal text-[16px] text-[#efefefcc]">
                            {token.balance}
                          </td>
                        </Tooltip>
                      )}

                      <td className="px-6 py-4 font-normal text-[16px] text-[#efefefcc]">
                        ${Number(token.price * token.balance).toFixed(8)}
                      </td>
                      {/* <td className="px-6 py-4 w-[125px]">
                        {`${allocation}%`}
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default TotalBalance;
