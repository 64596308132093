import Divider from "../../assets/divider.png";
import Solana from "../../assets/Solana.png";
import Eth from "../../assets/eth.png";
import Polygon from "../../assets/Polygon.png";
import Aptos from "../../assets/Aptos.png";
import Sui from "../../assets/Sui.png";
import Starknet from "../../assets/starknet.png";
import { NavLink } from "react-router-dom";
import { initilize } from "./loginUtilities";
import { BounceLoader } from "react-spinners";
import { useState } from "react";
import { dividerClasses } from "@mui/material";

function ChooseWallet(props: any) {
  const { chooseChain, setChooseChain, setChain } = props;

  return (
    <div className="flex justify-center" style={{ fontFamily: "poppins" }}>
      <div className="mt-[126px] pb-[51px] px-[52px] bg-[#ffffff05] text-left w-[600px]">
        <div className="mt-[35px] text-[24px] font-[500]">
          Create your first project
        </div>
        <div className="mt-[17px] text-[20px] font-[500] text-[#c8c8c8]">
          Choose the blockchain
        </div>
        {/* <div className="mt-[10px] text-[20px] font-normal text-[#575757]">Mainnet</div> */}
        <div className="grid grid-cols-2 gap-x-[20px] gap-y-[17px] mt-[15px]">
          <button
            onClick={() => {
              setChooseChain("Solana");
              setChain("Solana");
            }}
            className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"
          >
            <img src={Solana} />
          </button>
          {/* <button className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"><img src={Polygon} /></button> */}
          <button
            onClick={() => {
              setChooseChain("Starknet");
              setChain("Starknet");
            }}
            className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"
          >
            <img src={Starknet} />
          </button>
          {/* <button className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"><img src={Eth} /></button> */}
          <a href="https://form.jotform.com/230262019826452">
            <button className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]">
              <img src={Aptos} />
            </button>
          </a>
          {/* <button
            onClick={() => {
              setChooseChain("Polygon");
              setChain("Polygon");
            }}
            className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"
          >
            <img src={Polygon} />
          </button> */}

          {/* <button className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"><img src={Sui} /></button> */}
        </div>
        {/* <div className="mt-[35px]"><img src={Divider} /></div>
                <div className="mt-[29px] text-normal text-[20px] text-[#575757]">Devnet</div>
                <div className="grid grid-cols-2 gap-x-[20px] gap-y-[17px] mt-[15px] pb-[60px]">
                    <button onClick={() => { setChooseChain("Solana"); setChain("Solana") }} className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"><img src={Solana} /></button>
                    <button className="h-[72px] w-full bg-[#ffffff05] text-left pl-[28px]"><img src={Starknet} /></button>
                </div> */}
      </div>
    </div>
  );
}

export default ChooseWallet;
