import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import './WalletNotConnected.css'
import ChooseWallet from '../ChooseChain/ChooseChain'
import { useState } from 'react'
import UserSignIn from '../UserSignIn/UserSignIn'

function WalletNotConnected(props: any) {
  const { wantToLogin, setWantToLogin, status, setStatus } = props
  // const [status, setStatus] = useState("Login")
  const handleSignInClick = () => {
    setWantToLogin(true)
  }
  return (
    <div className="relative">
      <div className='notconnected__container' style={{ fontFamily: "poppins" }}>
        <div className='notconnected__midcont mt-[80px] pt-[88px]'>
          <span className='sm:text-[80px] notconnected__experience'>Experience the </span><br />
          <span className='text-[87px] sm:text-[80px] colored-grad'>Power  of API</span>
          <div className="grid grid-cols-2 gap-[10px]">
            <div className="px-[79px] py-[22px] bg-[#ffffff05] text-[16px] font-normal text-[#efefefcc]">Easy to Integrate</div>
            <div className="px-[79px] py-[22px] bg-[#ffffff05] text-[16px] font-normal text-[#efefefcc]">Lightening Fast</div>
            <div className="px-[79px] py-[22px] bg-[#ffffff05] text-[16px] font-normal text-[#efefefcc]">Unlimited API Calls</div>
            <div className="px-[79px] py-[22px] bg-[#ffffff05] text-[16px] font-normal text-[#efefefcc]">Dedicated Support</div>
          </div>
          <div className="flex gap-[13.74px] items-center justify-center mt-[50px]">
            <button onClick={() => {
              handleSignInClick();
              // setStatus("Login") 
            }} className="h-[50px] w-[176px] flex justify-center items-center rounded border text-[#efefefcc] border-[#333232] text-[19.24px] font-normal hover:text-white">Sign In</button>
            <button onClick={() => {
              handleSignInClick();
              // setStatus("Get Started") 
            }} className="colored h-[50px] w-[176px] flex justify-center items-center rounded text-black text-[19.24px] bg-gradient-to-r from-[#F55151] to-[#FFB800] font-normal">Start for Free</button>
          </div>
        </div>
      </div>
      {wantToLogin === true ?
        <div className="backdrop-brightness-50 bg-[#0a0a0a]/30 rounded absolute mt-[-612px] h-[124%] w-full flex justify-center items-center">
          <UserSignIn status={status} setWantToLogin={setWantToLogin} {...props} />
        </div>
        : ""
      }
    </div>
  )
}

export default WalletNotConnected