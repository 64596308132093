function ContractDetails(props: any) {
  const {
    setFormValue,
    validateInput,
    formValues,
    setIsFile,
    setError,
    error,
    isFile,
    fileName,
    setFileName,
    chain,
  } = props;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValues,
      [name]: value,
    });
    validateInput(name, value);
  };

  const handleFileDelete = () => {
    setIsFile(false);
    setError({ ...error, idl: "Please upload ABI" });
  };

  const handleEnvChange = (e: any) => {
    const { value } = e.currentTarget;
    setFormValue({
      ...formValues,
      env: value,
    });
  };

  function handleFileUpload(event: any) {
    const file = event.target.files[0];
    setFileName(file.name.slice(0, 20));

    const reader = new FileReader();

    if (file) {
      reader.readAsText(file);
    }

    reader.addEventListener(
      "load",
      () => {
        setIsFile(true);
        const fileContent = reader.result;
        try {
          const data = JSON.parse(fileContent as string);
          setError({ ...error, idl: "" });
          if (!data) {
            setError({
              ...error,
              idl: "Please upload a valid ABI",
            });
          }
        } catch (e) {
          setError({ ...error, idl: "Please upload a valid ABI" });
        }

        setFormValue({ ...formValues, idl: fileContent });
      },
      false
    );
  }

  return (
    <>
      <div className="mb-5">
        {chain === "Polygon" && (
          <div className="flex flex-col items-start mb-4">
            <span>Select network</span>
            <select
              onChange={handleEnvChange}
              className="bg-[#ffffff1c] h-[50px] text-[#efefefcc] text-sm rounded-[4px] p-2.5"
            >
              <option className="bg-[#ffffff1c]" value="mainnet" selected>
                Mainnet
              </option>
              <option className="bg-[#ffffff1c]" value="testnet">
                Testnet
              </option>
            </select>
          </div>
        )}
        <label
          className="block text-left text-[#CAC8C8] text-sm mb-1"
          htmlFor="username"
        >
          Contract Address
        </label>
        <input
          name="programId"
          value={formValues.programId}
          onChange={handleChange}
          className="min-w-[520px] bg-[#ffffff1a] shadow appearance-none rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="0x04d0390b777b424e43839cd1e744799f3de6c176c7e32c1812a41dbd9c19db6a"
        />
        <span className="z-2 absolute text-xs text-[#FF5B5B] flex justify-start items-center pt-[4px] pb-[10px]">
          {error.programId}
        </span>
      </div>
      <div className="flex justify-center mt-1">
        <div className="w-full">
          <label
            className="block text-left text-[#CAC8C8] text-sm mb-2"
            htmlFor="username"
          >
            Upload ABI &#40;.json only&#41;
          </label>
          {!isFile ? (
            <div className="max-w-xl">
              <label className="hover:cursor-pointer bg-[#ffffff1a] flex justify-center w-full h-32 px-4 transition bg-[#1A1D23] appearance-none rounded-[5px] text-white leading-tight focus:outline-none focus:shadow-outline">
                <span className="flex-col items-center space-x-2">
                  <p className="flex justify-center py-2">
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32 32L24 24L16 32"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24 24V42"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M40.7809 36.78C42.7316 35.7165 44.2726 34.0337 45.1606 31.9972C46.0487 29.9607 46.2333 27.6864 45.6853 25.5333C45.1373 23.3803 43.8879 21.471 42.1342 20.1069C40.3806 18.7427 38.2226 18.0014 36.0009 18H33.4809C32.8755 15.6585 31.7472 13.4846 30.1808 11.642C28.6144 9.79927 26.6506 8.33567 24.4371 7.36118C22.2236 6.3867 19.818 5.92669 17.4011 6.01573C14.9843 6.10478 12.619 6.74057 10.4833 7.8753C8.34747 9.01003 6.49672 10.6142 5.07014 12.5671C3.64356 14.5201 2.67828 16.771 2.24686 19.1508C1.81544 21.5305 1.92911 23.977 2.57932 26.3065C3.22954 28.6359 4.39938 30.7877 6.0009 32.6"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M32 32L24 24L16 32"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </p>
                  <p>
                    <span className="text-lg text-white">Browse Files</span>
                  </p>
                </span>
                <input
                  accept="application/JSON"
                  type="file"
                  name="file_upload"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          ) : (
            <div className="flex w-[292px]flex-cols justify-between items-center bg-[#ffffff1a] h-[42px] rounded-[5px]">
              <div className="flex flex-cols pl-[12px]">
                <svg
                  width="18"
                  height="25"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6L10.5 1.5Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 1.5V6H15"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 9.75H6"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 12.75H6"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 6.75H6.75H6"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="pl-[4px]">{fileName}</span>
              </div>
              <span className="pr-[10px]" onClick={handleFileDelete}>
                <svg
                  className="cursor-pointer"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 4.5H3.75H15.75"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 8.25V12.75"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 8.25V12.75"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          <span className="absolute z-2 text-xs text-[#FF5B5B] flex justify-start items-center pt-[4px]">
            {error.ABI}
          </span>
        </div>
      </div>
    </>
  );
}

export default ContractDetails;
