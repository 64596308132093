import "./App.css";
import Landing from "./Pages/Landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { initializeApp } from "firebase/app";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { useEffect, useMemo, useState } from "react";
import { getAuth } from "@firebase/auth";
import OuterDashboard from "./Components/Playground/OuterDashboard/OuterDashboard";
import Profile from "./Components/Playground/Profile";
import Layout from "./Pages/Layout";

require("@solana/wallet-adapter-react-ui/styles.css");

const network = WalletAdapterNetwork.Mainnet;

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyD4atSrkDjG9nJHpcRBgA6w4FY-tZ-VgW8",
    authDomain: "app.conciselabs.io",
    projectId: "appclweb",
    storageBucket: "appclweb.appspot.com",
    messagingSenderId: "557542666778",
    appId: "1:557542666778:web:a4978eb15f7639087ed7a3",
    measurementId: "G-1RP5J22QE1",
  };

  // Initialize Firebase
  initializeApp(firebaseConfig);
};

function App(props: any) {
  const [googleAuthResp, setGoogleAuthResp] = useState<any>(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [chooseChain, setChooseChain] = useState("");
  const [tab, setTab] = useState<any>("");
  const [wantToLogin, setWantToLogin] = useState(false);
  const [status, setStatus] = useState("Login");
  const [chain, setChain] = useState("");
  const [email, setEmail] = useState<any>("");
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const [currentUser, setCurrentUser] = useState<any>({
    apiToken:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZPY3M5cmQxVkFaVlZLUkZidjhHNElsY0hPYzIiLCJpYXQiOjE2NzM1MTAzNTZ9.yCDCIgXXLLg8w2Ww_APt7YBnQ1SEf56C680-xc3pDbg",
  });

  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter({ network })],
    []
  );

  useEffect(() => {
    initFirebase();
  }, []);

  useEffect(() => {
    if (window.location.href.includes("api")) {
      setTab("/api");
    } else if (window.location.href.includes("playground")) {
      setTab("/playground");
    } else if (window.location.href.includes("profile")) {
      setTab("/profile");
    } else {
      setTab("/");
    }
    const auth = getAuth();
    const user = auth.currentUser;
    // console.log("current user", currentUser);
    !currentUser && setCurrentUser(user);
  });

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Layout
                  status={status}
                  setStatus={setStatus}
                  googleAuthResp={googleAuthResp}
                  chooseChain={chooseChain}
                  setTab={setTab}
                  tab={tab}
                  setWantToLogin={setWantToLogin}
                  wantToLogin={wantToLogin}
                  email={email}
                  setEmail={setEmail}
                  walletAddress={walletAddress}
                  setWalletAddress={setWalletAddress}
                  currentUser={currentUser}
                  setCurrentUser={setCurrentUser}
                  {...props}
                />
              }
            >
              <Route
                index
                path="/playground"
                element={
                  <OuterDashboard currentUser={currentUser} {...props} />
                }
              />
              <Route
                path="/api"
                element={
                  <Landing
                    setGoogleAuthResp={setGoogleAuthResp}
                    chooseChain={chooseChain}
                    setChooseChain={setChooseChain}
                    chain={chain}
                    setChain={setChain}
                    walletAddress={walletAddress}
                    setWantToLogin={setWantToLogin}
                    wantToLogin={wantToLogin}
                    email={email}
                    setEmail={setEmail}
                    setWalletAddress={setWalletAddress}
                    currentUser={currentUser}
                    {...props}
                  />
                }
              />
              {/* <Route
                path="account"
                element={<Account currentUser={currentUser} {...props} />}
              /> */}
              <Route
                path="/profile"
                element={
                  <Profile
                    currentUser={currentUser}
                    email={email}
                    walletAddress={walletAddress}
                    {...props}
                  />
                }
              />
            </Route>
          </Routes>
          {/* {currentUser === null && (
            <LoginModal setCurrentUser={setCurrentUser} />
          )}
          {currentUser && (
            <OnboardingDialog
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          )} */}
        </BrowserRouter>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
