// import { useState } from "react"
// import { useWallet } from "@solana/wallet-adapter-react";
// import Navbar from "../Components/Navbar/Navbar";
import WalletConnected from "../Components/WalletConnected/WalletConnected";
import WalletNotConnected from "../Components/WalletNotConnected/WalletNotConnected";
import "./Landing.css";
import ChooseChain from "../Components/ChooseChain/ChooseChain";

function Landing(props: any) {
  const { walletAddress, email, chain, chooseChain } = props;

  return (
    <>
      {/* <Navbar {...props} /> */}
      <div className="w-full pb-[40px]">
        {/* {!wallet.publicKey && <WalletNotConnected {...props} />}
        {wallet.publicKey && <WalletConnected />} */}
        {/* {walletAddress === "" || email === "" ? <WalletNotConnected {...props} /> : <WalletConnected {...props} />} */}
        {walletAddress === "" || email === "" ? <WalletNotConnected {...props} /> : chain === "" && chooseChain === "" ? <ChooseChain {...props} /> : <WalletConnected {...props} />}
      </div>
    </>
  );
}

export default Landing;
