import './MetricsData.css'
import MetricsBorderSVG from '../../assets/UsageMetricsBorder.svg'
import { useMediaQuery } from '@mui/material'

type MetricsDataProps = {
    lastOneDay: number,
    lastOneHr: number,
    errors: number,
}

function MetricsData(props: MetricsDataProps) {
  const isMobileScreen = useMediaQuery('(max-width:450px)')

  return (
    <div className="metrics__usage flex flex-col sm:flex-row">
        <div className="metrics__cont mr-[40px]">
            <div className="metrics__number">
                {props.lastOneDay === -1 || props.lastOneDay === undefined ? '-' : props.lastOneDay}
            </div>
            <div className="metrics__text">
                Requests Last 1 Day
            </div>
            {isMobileScreen && <img src={MetricsBorderSVG} alt="line" className='metrics__line' />}
        </div>
        <div className="metrics__cont mr-[40px] metrics_padded border-l-0 sm:border-l pl-0 sm:pl-[40px]">
            <div className="metrics__number">
                {props.lastOneHr === -1 || props.lastOneHr === undefined ? '-' : props.lastOneHr}
            </div>
            <div className="metrics__text">
                Requests Last 1 Hour
            </div>
            {isMobileScreen && <img src={MetricsBorderSVG} alt="line" className='metrics__line' />}
        </div>
        <div className="metrics__cont metrics_padded border-l-0 sm:border-l pl-0 sm:pl-[40px]">
            <div className="metrics__number">
                {props.errors === -1 || props.errors === undefined ? '-' : props.errors}
            </div>
            <div className="metrics__text">
                Errors
            </div>
        </div>
    </div>
  )
}

export default MetricsData