import { useState } from 'react'
import copyImg from "../../../../assets/copy.png"
import copyDone from "../../../../assets/copied.png"

function DisplayNFTCollection(props: any) {
    const { data, handleImageUrl, displayChain } = props
    const [copyIndex, setCopyIndex] = useState<any>(null)
    const [isCopied, setIsCopied] = useState(false)

    async function copyTextToClipboard(value: any, key: any) {
        setIsCopied(key)
        setTimeout(() => {
            setIsCopied(false)
        }, 2000)
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(value);
        } else {
            return document.execCommand("copy", true, value);
        }

    }
    return (
        <>
            {Object.keys(data).slice(0, 5).map((key: any) =>
                <div className="bg-[#0000006e] p-[18px] rounded" key={key}>
                    <img className="rounded" src={`${handleImageUrl(data[key].metadata.image)}`} alt="" />
                    <div className="pt-[13px] pb-[4px]">
                        <div className="flex justify-start items-center">
                            <p className="text-[#575757] text-[16px] mr-[21px]">NFT ID</p>
                            <p className="text-[#efefefcc] text-[16px]">#{data[key]['id']}</p>
                        </div>
                        <div className="flex justify-start items-center">
                            <p className="text-[#575757] text-[16px] mr-[21px]">Owner</p>
                            <p className="text-[#efefefcc] text-[16px]">{`${data[key]['owner'].slice(0, 4)}...${data[key]['owner'].slice(-4)}`}</p>
                            {isCopied === key ?
                                <img width="6%" className="cursor-pointer ml-[10px]" src={copyDone} alt="" />
                                :
                                <img width="6%" onClick={() => copyTextToClipboard(data[key]['owner'], key)} className="cursor-pointer ml-[10px]" src={copyImg} alt="" />
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DisplayNFTCollection